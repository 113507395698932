import React from 'react';
import { useEffect } from 'react';
import {
  Link as NativeBaseLink,
  Image,
  Heading,
  Flex,
  VStack,
  Center,
  Text,
  Box,
  useBreakpointValue,
  Skeleton,
} from 'native-base';
import { useLocation } from 'react-router-dom';
import { Link } from '../ui/Link';
import HeaderNav from '../HeaderNav/HeaderNav';
import { useAnalyticsHelper } from '../../hooks/useAnalyticsHelper';
import { useSegment } from '../../hooks/useSegment';
import { LOCAL_STORAGE_USER_INFO_KEY } from '../../constants/misc';
import {
  TRACKING_DOWNLOAD_APP_PAGE,
  TRACKING_PURCHASE_LOCAL_STORAGE_KEY,
} from '../OnboardingForm/constants';
import { useThankYouPage } from './useThankYouPage';
import styled from '@emotion/styled';

const qrCode = './../onelinkto_nqnxxq-cp.png';
const appImg = './../2screenshots.png';
const footerImg = './../Ayble-LLLo-21.png';
const iosBtn = './../Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.png';
const playStoreBtn = './../google-play-badge.png';
const iosDownloadLink =
  'https://apps.apple.com/us/app/ayble-health-gi-gut-care/id1642365544';
const androidDownloadLink =
  'https://play.google.com/store/apps/details?id=com.ayblehealth.app';

const ImgContainer = styled('div')`
  & > div > div {
    background-position: top;
  }
`;

function ThankYou() {
  const { updateTrackingLayer } = useAnalyticsHelper();
  const { page: pageView } = useSegment();
  const { state } = useLocation();
  const { coachInfo, providerInfo } = useThankYouPage();

  const smallScreen = useBreakpointValue({
    base: true,
    md: false,
  });

  const trackPurchaseComplete = !!localStorage.getItem(
    TRACKING_PURCHASE_LOCAL_STORAGE_KEY
  );

  useEffect(() => {
    pageView(TRACKING_DOWNLOAD_APP_PAGE);

    if (trackPurchaseComplete) {
      updateTrackingLayer(`Thank You`);
      localStorage.removeItem(TRACKING_PURCHASE_LOCAL_STORAGE_KEY);
      localStorage.removeItem(LOCAL_STORAGE_USER_INFO_KEY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeaderNav page={1} hideBack />

      <Center>
        <VStack space={1} px={'3'} mb={10}>
          <Box mt={10} alignItems={'center'}>
            <Heading fontSize={'4xl'}>
              Hooray! You have subscribed to Ayble!
            </Heading>

            {state?.eligible && (
              <Heading
                width={'80%'}
                lineHeight={'30'}
                mt={'5'}
                fontSize={'2xl'}
              >
                Your eligibility was verified and you can now start using Ayble!
              </Heading>
            )}

            <Heading mt={'5'} fontSize={'2xl'}>
              Follow these next steps to achieve better gut health
            </Heading>
          </Box>

          <VStack mt={16} alignItems={'center'}>
            <Center w={{ lg: '80%', xl: '60%', '2xl': '65%' }}>
              <Flex direction={smallScreen ? 'column-reverse' : 'row'} mb={24}>
                <VStack
                  alignItems={'center'}
                  justify={'center'}
                  maxW={'670px'}
                  flex={2}
                >
                  <Box
                    alignItems={'center'}
                    minH={{ base: '120', md: '120', lg: '120' }}
                  >
                    <Image
                      alt="Ayble App"
                      resizeMode={'contain'}
                      maxWidth={300}
                      width={150}
                      h={smallScreen ? 48 : '100%'}
                      source={{
                        uri: qrCode,
                      }}
                    />
                  </Box>

                  <Flex
                    direction="row"
                    justify={'center'}
                    alignItems={'space-between'}
                    w={'100%'}
                    mt={2}
                  >
                    <NativeBaseLink w={150} href={iosDownloadLink} isExternal>
                      <Image
                        alt="Ayble App"
                        h={44}
                        resizeMode={'contain'}
                        source={{
                          uri: iosBtn,
                        }}
                        w={'100%'}
                      />
                    </NativeBaseLink>

                    <NativeBaseLink
                      w={150}
                      href={androidDownloadLink}
                      isExternal
                    >
                      <Image
                        alt="Ayble App"
                        h={44}
                        resizeMode={'contain'}
                        source={{
                          uri: playStoreBtn,
                        }}
                        w={'100%'}
                      />
                    </NativeBaseLink>
                  </Flex>
                </VStack>

                <VStack flex={3} px={4}>
                  <Heading textAlign={'left'} fontSize={'2xl'} mb={3}>
                    Step 1. Download the Ayble app on your smartphone
                  </Heading>
                  <Text fontSize={'lg'} fontWeight={'normal'}>
                    If you are on your mobile device, click on{' '}
                    <Link href={iosDownloadLink}>App Store</Link> or{' '}
                    <Link href={androidDownloadLink}>Google Play</Link> store
                    icon to download the app. Otherwise, scan the QR code with
                    your device camera to be directed to the correct app store.
                  </Text>
                </VStack>
              </Flex>

              <Flex direction={smallScreen ? 'column-reverse' : 'row'} mb={24}>
                <VStack
                  alignItems={'center'}
                  justify={'center'}
                  maxW={'670px'}
                  flex={2}
                >
                  <Box
                    pt={smallScreen ? 6 : null}
                    alignItems={'center'}
                    minH={{ base: '120', md: '120', lg: '120' }}
                  >
                    <Image
                      alt="App Screenshot"
                      resizeMethod={'scale'}
                      resizeMode={'contain'}
                      maxWidth={300}
                      width={150}
                      h={48}
                      source={{
                        uri: appImg,
                      }}
                    />
                  </Box>
                </VStack>

                <VStack flex={3} px={4}>
                  <Heading textAlign={'left'} fontSize={'2xl'} mb={3}>
                    Step 2. Log in to the Ayble mobile app
                  </Heading>
                  <Text fontSize={'lg'} fontWeight={'normal'}>
                    Our passwordless login makes it easy to jump right in.
                    Simply use the email address you used to register to log in
                    to the mobile app. Once you’re in, our tutorials will help
                    get you going.
                  </Text>
                </VStack>
              </Flex>

              {(coachInfo.isLoading || coachInfo?.data) && (
                <Flex
                  direction={smallScreen ? 'column-reverse' : 'row'}
                  w={'100%'}
                  mb={20}
                >
                  <VStack
                    alignItems={'center'}
                    justify={'center'}
                    maxW={'670px'}
                    flex={2}
                  >
                    <Box
                      pt={smallScreen ? 6 : null}
                      alignItems={'center'}
                      minH={{ base: '120', md: '120', lg: '120' }}
                    >
                      {coachInfo.isLoading ? (
                        <Skeleton width={150} h={150} rounded="full" />
                      ) : (
                        <Image
                          alt="App Screenshot"
                          resizeMode={'cover'}
                          className={'test'}
                          maxWidth={300}
                          width={150}
                          h={150}
                          borderRadius={100}
                          source={{
                            uri: coachInfo.data.image_url,
                          }}
                        />
                      )}
                    </Box>
                  </VStack>

                  <VStack flex={3} px={4}>
                    {coachInfo.isLoading ? (
                      <Skeleton mb="3" rounded="md" />
                    ) : (
                      <Heading textAlign={'left'} fontSize={'2xl'} mb={3}>
                        Step 3. Meet your Ayble care team
                      </Heading>
                    )}
                    {coachInfo.isLoading ? (
                      <Skeleton.Text />
                    ) : (
                      <Text fontSize={'lg'} fontWeight={'normal'}>
                        You have been paired with your own personal Health
                        Coach, {coachInfo.data.first_name}, to help you through
                        this journey.{' '}
                        {!providerInfo?.data && (
                          <>
                            <Link href={coachInfo.data.schedule_link}>
                              Schedule your first meeting
                            </Link>
                            , and be on the lookout for an introductory email
                            from {coachInfo.data.first_name}!
                          </>
                        )}
                      </Text>
                    )}

                    {providerInfo.isLoading ||
                      (providerInfo?.data &&
                        (providerInfo?.isLoading ? (
                          <Skeleton.Text />
                        ) : (
                          <Text fontSize={'lg'} fontWeight={'normal'} mt={4}>
                            You also have been paired with a provider,{' '}
                            {providerInfo.data.full_name}, with whom you will
                            have an introductory call to set you on the right
                            path.{' '}
                            <Link href={providerInfo.data.calendar_url}>
                              Schedule your first meeting now!
                            </Link>
                          </Text>
                        )))}

                    {coachInfo.isLoading ? (
                      <Skeleton h={2} mt="4" rounded="md" />
                    ) : (
                      <Text
                        textAlign={'left'}
                        mt={4}
                        fontWeight={'normal'}
                        italic
                      >
                        Note: you can do this step at any time later, through
                        the mobile app.{' '}
                      </Text>
                    )}
                  </VStack>
                </Flex>
              )}
            </Center>
          </VStack>

          <Center>
            <ImgContainer>
              <Image
                size={120}
                resizeMethod={'scale'}
                resizeMode={'cover'}
                borderRadius={'full'}
                source={{
                  uri: footerImg,
                }}
                alt="Footer Image"
              />
            </ImgContainer>

            <Text fontWeight={'normal'} mt={4} textAlign={'center'}>
              Need Help? Contact{' '}
              <Link href="mailto:support@ayblehealth.com">
                support@ayblehealth.com
              </Link>
            </Text>
          </Center>
        </VStack>
      </Center>
    </>
  );
}

export default ThankYou;
