import styled from '@emotion/styled';
import {
  Box,
  Flex,
  Heading,
  Image,
  useBreakpointValue,
  VStack,
} from 'native-base';

const vogueLogoUrl = '../../vogue_logo.png';
const glamourLogoUrl = '../../glamour_logo.png';
const everydayHealthLogoUrl = '../../everyday_health_logo.png';
const ReadersDigestLogoUrl = '../../readers_digest_logo.png';

const LOGOS = [
  vogueLogoUrl,
  glamourLogoUrl,
  everydayHealthLogoUrl,
  ReadersDigestLogoUrl,
];

const ImgContainer = styled(Box)`
  & > div > div {
    background-position: left;
  }
`;

const Grid = styled('div')<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: ${({ isMobile }) =>
    isMobile ? 'repeat(2, 1fr);' : 'repeat(4, 1fr);'};
  grid-template-rows: ${({ isMobile }) =>
    isMobile ? 'repeat(2, 1fr);' : 'repeat(1, 1fr);'};
  grid-column-gap: ${({ isMobile }) => (isMobile ? '8px' : null)};
  grid-row-gap: ${({ isMobile }) => (isMobile ? '8px' : null)};
`;

export const Logos = () => {
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  });

  return (
    <VStack py={12}>
      <Heading>As Seen In:</Heading>

      <Flex
        alignItems={'center'}
        direction={isMobile ? 'column' : 'row'}
        justifyContent={'center'}
        pt={5}
        px={5}
      >
        <Grid isMobile={isMobile}>
          {LOGOS.map((logoUrl) => (
            <ImgContainer key={logoUrl} px={[0, 0, 0, 4]}>
              <Image
                alt={'Testimonial logo'}
                height={{ base: '130px', md: '200px', lg: '300px' }}
                resizeMode={'contain'}
                size={'2xl'}
                source={{
                  uri: logoUrl,
                }}
                width={{ base: '180px', md: '260px' }}
              />
            </ImgContainer>
          ))}
        </Grid>
      </Flex>
    </VStack>
  );
};
