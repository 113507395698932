import { useState, useEffect } from 'react';
import { VStack, HStack, Center, Button } from 'native-base';
import IBSViewOne from './AssessmentRangeSliderForms/IBSViewOne.js';
import IBSViewTwo from './AssessmentRangeSliderForms/IBSViewTwo.js';
import AssessmentWrapUp from './AssessmentWrapUp.js';
import { useAnalyticsHelper } from '../../../hooks/useAnalyticsHelper.js';

function AssessmentIBS({ formData, dispatch, setParentPage }) {
  const { updateTrackingLayer } = useAnalyticsHelper();
  const [page, setPage] = useState(0);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    scrollToTop();
  }, [page]);

  const PageDisplay = () => {
    if (page === 0) {
      return <IBSViewOne formData={formData} dispatch={dispatch} />;
    } else if (page === 1) {
      return <IBSViewTwo formData={formData} dispatch={dispatch} />;
    } else if (page === 2) {
      return <AssessmentWrapUp user={formData} />;
    }
  };

  useEffect(() => {
    // page 10 is the assessments and they send their own update to the dataLayer
    updateTrackingLayer(`Diagnosis C, page - ${page + 1}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <Center>
      <VStack space={1} className="personal-info-container">
        {PageDisplay()}
        <Center>
          <HStack mt={4}>
            {page === 0 && (
              <Button
                minWidth={'300px'}
                size={'lg'}
                onPress={() => {
                  setPage((currPage) => currPage + 1);
                }}
                testID={'assessment-ibs-page-0-next-button'}
              >
                {'Next'}
              </Button>
            )}
            {page === 1 && (
              <>
                <Button
                  mr={2}
                  minWidth={'175px'}
                  size={'lg'}
                  onPress={() => {
                    setPage((currPage) => currPage - 1);
                  }}
                  testID={'assessment-ibs-page-1-previous-button'}
                >
                  {'Previous'}
                </Button>
                <Button
                  minWidth={'175px'}
                  size={'lg'}
                  onPress={() => {
                    setPage((currPage) => currPage + 1);
                  }}
                  testID={'assessment-ibs-page-1-next-button'}
                >
                  {'Next'}
                </Button>
              </>
            )}
            {page === 2 && (
              <Button
                minWidth={'300px'}
                size={'lg'}
                onPress={() => {
                  setParentPage((currPage) => currPage + 1);
                }}
                testID={'assessment-ibs-page-2-button'}
              >
                {"Let's Go!"}
              </Button>
            )}
          </HStack>
        </Center>
      </VStack>
    </Center>
  );
}

export default AssessmentIBS;
