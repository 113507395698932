import { useApi } from '../../hooks';
import { useQuery } from '@tanstack/react-query';

export type ProviderPublicInfo = {
  first_name: 'string';
  full_name: 'string';
  title: 'string';
  bio: 'string';
  image_url: 'string';
  calendar_url: 'string';
};

export const useProviderPublicInfo = (providerId: string | undefined) => {
  const { api } = useApi();

  const fetchPublicInfo = async (): Promise<ProviderPublicInfo> => {
    return api({
      route: `/care_team_member/${providerId}/`,
    });
  };

  const { data, isLoading, isError } = useQuery<ProviderPublicInfo, Error>(
    ['public-provider-info'],
    fetchPublicInfo,
    { enabled: !!providerId, refetchOnWindowFocus: false }
  );

  return {
    isLoading,
    isError,
    data,
  };
};
