import React from 'react';
import { VStack, Box, Text, Heading, Image, HStack } from 'native-base';

const girlImg = './../Ayble-LLLo-21.png';

const title = 'You’re eligible! Welcome to the Ayble Experience';
const subtitle =
  'We build you a personalized program for a happier, healthier gut - based on science.';
const listTitle =
  'Here is an example of what your journey\nwith Ayble could look like:';
const weeks = [
  {
    title: 'Week 1',
    body: 'Trial your program and meet your coach',
  },
  { title: 'Week 2-3', body: 'Start identifying potential trigger foods' },
  { title: 'Week 4-5', body: 'Learn about how the mind and gut communicate' },
  { title: 'Week 6-8', body: 'Access on-going health coaching' },
  {
    title: 'Week 9-10',
    body: 'Build skills to manage your stress and anxiety',
  },
  { title: 'Week 11-12', body: 'Begin integrating new dietary insights' },
  { title: 'Week 12+', body: 'Sustain and/or improve results' },
];

function ValueProposition() {
  return (
    <VStack
      className="other-info-container"
      testID={'onboarding-value-proposition'}
    >
      <Heading fontSize={'3xl'} mb={2}>
        {title}
      </Heading>

      <Heading fontFamily="body" fontWeight={'300'} size={'md'} mb={8}>
        {subtitle}
      </Heading>

      <HStack flexDir={{ base: 'column', lg: 'row' }}>
        <VStack>
          <Heading
            fontFamily="body"
            fontSize={'xl'}
            fontWeight={300}
            mb={6}
            style={{ textAlign: 'left' }}
          >
            {listTitle}
          </Heading>

          {weeks.map((week, index) => (
            <VStack key={index} mb={8}>
              <Heading
                fontFamily="body"
                fontSize={'xl'}
                fontWeight={'bold'}
                style={{ textAlign: 'left' }}
              >
                {week.title}
              </Heading>
              <Text fontSize={'xl'}>{week.body}</Text>
            </VStack>
          ))}
        </VStack>

        <Box flex={1} w={{ base: '100%', lg: 270 }}>
          <Image
            alt={'girl with phone image'}
            size={'lg'}
            resizeMode={'contain'}
            height={'100%'}
            minH={'400px'}
            width={'100%'}
            source={{
              uri: girlImg,
            }}
          />
        </Box>
      </HStack>
    </VStack>
  );
}

export default ValueProposition;
