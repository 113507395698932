import React from "react";
import { VStack, Pressable, Text, Box } from "native-base";
import { FORM_ACTIONS } from "../../utils/formActions.js"

function UndiagnosedSymptomsInfo({ formData, dispatch }) {

  return (
    <VStack space={3} className="personal-info-container">
      <Pressable onPress={() => {
          dispatch({ type: FORM_ACTIONS.TOGGLE_FORM_DATA, fieldName: 'bloatingFlatulence' })
        }}>
        {() => {
        return <Box bg={formData.bloatingFlatulence ? "secondary.500" : "light.50"} p="5" borderWidth="1" borderColor="muted.400" borderRadius="lg" style={{}}>
          <Text color={formData.bloatingFlatulence ? "light.50": "secondary.500"}  fontWeight="medium" fontSize="xl">
            Bloating / flatulence
          </Text>
        </Box>
      }}
      </Pressable>

      <Pressable onPress={() => {
          dispatch({ type: FORM_ACTIONS.TOGGLE_FORM_DATA, fieldName: 'stomachPain' })
        }}>
        {() => {
        return <Box bg={formData.stomachPain ? "secondary.500" : "light.50"} p="5" borderWidth="1" borderColor="muted.400" borderRadius="lg" style={{}}>
          <Text color={formData.stomachPain ? "light.50": "secondary.500"}  fontWeight="medium" fontSize="xl">
            Stomach / abdominal pain
          </Text>
        </Box>
      }}
      </Pressable>

      <Pressable onPress={() => {
          dispatch({ type: FORM_ACTIONS.TOGGLE_FORM_DATA, fieldName: 'constipation' })
        }}>
        {() => {
        return <Box bg={formData.constipation ? "secondary.500" : "light.50"} p="5" borderWidth="1" borderColor="muted.400" borderRadius="lg" style={{}}>
          <Text color={formData.constipation ? "light.50": "secondary.500"}  fontWeight="medium" fontSize="xl">
            Constipation
          </Text>
        </Box>
      }}
      </Pressable>

      <Pressable onPress={() => {
          dispatch({ type: FORM_ACTIONS.TOGGLE_FORM_DATA, fieldName: 'diarrhea' })
        }}>
        {() => {
        return <Box bg={formData.diarrhea ? "secondary.500" : "light.50"} p="5" borderWidth="1" borderColor="muted.400" borderRadius="lg" style={{}}>
          <Text color={formData.diarrhea ? "light.50": "secondary.500"}  fontWeight="medium" fontSize="xl">
            Diarrhea
          </Text>
        </Box>
      }}
      </Pressable>

      <Pressable onPress={() => {
          dispatch({ type: FORM_ACTIONS.TOGGLE_FORM_DATA, fieldName: 'generallyNotWell' })
        }}>
        {() => {
        return <Box bg={formData.generallyNotWell? "secondary.500" : "light.50"} p="5" borderWidth="1" borderColor="muted.400" borderRadius="lg" style={{}}>
          <Text color={formData.generallyNotWell ? "light.50": "secondary.500"}  fontWeight="medium" fontSize="xl">
            Generally not feeling well
          </Text>
        </Box>
      }}
      </Pressable>
    </VStack>
  );
}

export default UndiagnosedSymptomsInfo;