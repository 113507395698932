import React, { useMemo } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import {
  Box,
  Card,
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Text,
  VStack,
} from 'native-base';
import { useDashboardPageState } from '../../hooks/useDashboardPageState';
import { Loader } from '../Loader';
import { Tabs } from '../Tabs/Tabs';

const partyEmoji = '../party.svg';

export const UserDashboardPage = ({ content, header }) => {
  const {
    state: { loading: isFetchingDashboardData, user },
  } = useDashboardPageState();
  const [searchParams] = useSearchParams();

  const routes = useMemo(
    () => [
      {
        key: 'first',
        path: `/user-details/${user?.id}`,
        searchParams,
        title: 'Overview',
      },
      {
        key: 'second',
        path: `/user-details/${user?.id}/charting-notes`,
        searchParams,
        title: 'Charting Notes',
      },
      {
        key: 'third',
        path: `/user-details/${user?.id}/goals`,
        searchParams,
        title: 'Goals',
      },
    ],
    [searchParams, user?.id]
  );

  const currentStreakDays = user?.days_of_current_streak;
  const streakDays =
    typeof currentStreakDays === 'number' ? currentStreakDays : '- -';

  return (
    <div
      className="dashboard-page"
      style={{
        height: '100vh',
        overflow: 'auto',
      }}
    >
      {header}

      {isFetchingDashboardData && (
        <Box backgroundColor="secondary.500" py={6} height={'100%'}>
          <Loader loading={isFetchingDashboardData} />
        </Box>
      )}

      {user && (
        <Box pb={0} bg="secondary.500">
          <Center>
            <Container maxW={'400px'} pb={6}>
              <Flex direction="column" minW={'300px'}>
                <VStack space={1} className="header">
                  <Center>
                    <Heading color={'muted.100'} textAlign="center" size="xl">
                      {user.first_name} {user.last_name}
                    </Heading>

                    <Text textAlign="center" color={'muted.100'} fontSize="sm">
                      {user.email}
                    </Text>

                    <Text textAlign="center" color={'muted.100'} fontSize="sm">
                      {user.id}
                    </Text>

                    {streakDays >= 2 && (
                      <Card
                        my={4}
                        h={'16px'}
                        w={'auto'}
                        bg={'yellow.400'}
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Text
                          fontWeight={'semibold'}
                          fontSize={'md'}
                          color={'secondary.500'}
                          pr={'2'}
                        >
                          {`${streakDays} day streak`}
                        </Text>
                        <Image
                          size={['24px', '24px']}
                          source={{
                            uri: partyEmoji,
                          }}
                          alt="party emoji"
                        />
                      </Card>
                    )}
                  </Center>
                </VStack>
              </Flex>
            </Container>

            <Box w={'50%'}>
              <Tabs routes={routes} />
            </Box>

            {content}
          </Center>
        </Box>
      )}
      <Outlet />
    </div>
  );
};
