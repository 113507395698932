export const isYesGiConditionOnlyForOtherDiagnosis = (formData) => {
  const {
    diagnosis_ids,
    hasCrohnsDisease,
    hasUlcerativeColitis,
    ibsConstipation,
    ibsDiarrhea,
    ibsMixed,
    ibsUnspecified,
    yesDiagnosedGiCondition,
  } = formData;

  return (
    yesDiagnosedGiCondition &&
    !!diagnosis_ids.length &&
    !hasCrohnsDisease &&
    !hasUlcerativeColitis &&
    !ibsConstipation &&
    !ibsDiarrhea &&
    !ibsMixed &&
    !ibsUnspecified
  );
};
