import { Accordion, Link, Text, View } from 'native-base';
import React, { useMemo } from 'react';
import { format } from 'date-fns';

export const MeetingHeader = ({ item }) => {
  const meetingType = item.meeting_type

  const formattedDate = useMemo(() => {
    if (!item.meeting_date) {
      return !item.id ? 'New meeting' : '[date not available]';
    }

    const dateObj = new Date(item.meeting_date);
    const headerDateString = format(dateObj, "MM-dd-yyyy 'at' KK:mm aaa");
    return `${headerDateString}${meetingType ? ':' : ''}`;
  }, [item, meetingType]);

  return (
    <View
      style={{
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        minHeight: '56px',
        paddingTop: 16,
        paddingBottom: 16,
      }}
    >
      <Accordion.Icon
        color={'black'}
        style={{
          //@ts-ignore
          cursor: 'pointer',
        }}
      />
      <Text fontSize={'md'} fontWeight={'normal'}>
        {formattedDate}
      </Text>

      {item.meeting_type && !!item.notes_url && (
        <Link
          isExternal
          ml={1}
          zIndex={5}
          href={item.notes_url}
          _text={{
            fontSize: 'xs',
            _light: {
              color: 'primary.600',
            },
            color: 'primary.600',
            textTransform: 'none',
          }}
          _hover={{
            _text: {
              _light: {
                color: 'primary.700',
              },
              color: 'primary.700',
            },
          }}
          onPress={(evt) => {
            evt.stopPropagation();
          }}
        >
          {meetingType}
        </Link>
      )}

      {item.meeting_type && !item.notes_url && (
        <Text style={{ fontWeight: '600' }}> {meetingType}</Text>
      )}
    </View>
  );
};
