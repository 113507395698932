import ReactGA from 'react-ga4';
import { useCallback } from 'react';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

//Array for doing lookup by page number from main Form
const trackingViewNames = [
  'Name + email',
  'Program Goals',
  'Init Value prop',
  'Sex',
  'Gender',
  'Ethinicity',
  'Pregnancy',
  'Eating Disorder',
  'Second Value prop',
  'Diagnosis',
  '', //This is blank to allow assessments to fill this slot with custom strings.
  'Diet Intro',
  'Food Allergies',
  'Dietary restrictions',
  'Avoided foods',
  'Ayble Helps Evidence',
  // These pages send their own custom string into this function and don't need to be looked up.
  // 'Create account',
  // 'Select plan',
  // 'Thank you'
];

export const useAnalyticsHelper = () => {
  const sendDataToGTM = useGTMDispatch();

  const updateTrackingLayer = useCallback(
    (pageNum, addParam) => {
      // test if input is string or Num
      let lookup;
      if (typeof pageNum === 'string') {
        lookup = pageNum;
      } else {
        lookup = trackingViewNames[pageNum];
      }
      let payload = `${lookup}${addParam ? ` - ${addParam}` : ''}`;

      // send page view event
      ReactGA.send({ hitType: 'pageview', page_title: payload });
      sendDataToGTM({ event: 'page_view', page_title: payload });
    },
    [sendDataToGTM]
  );

  return { updateTrackingLayer };
};
