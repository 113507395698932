import {
  VStack,
  Pressable,
  Text,
  Box,
  Heading,
  HStack,
  Center,
} from 'native-base';
import { FORM_ACTIONS } from '../../utils/formActions.js';

export const GISurgery = ({ formData, dispatch, hasHadGISurgery }) => {
  const updateGISurgery = (data) => {
    dispatch({
      type: FORM_ACTIONS.UPDATE_FORM_FIELD,
      fieldName: `hasHadGISurgery`,
      payload: data,
    });
  };

  if (hasHadGISurgery) {
    return (
      <VStack maxWidth={'100%'} space={1} className="personal-info-container">
        <Heading>
          Unfortunately, Ayble is not currently designed to support those who
          have history of GI surgeries. We hope to carry out future scientific
          studies to understand nutrition and gut health post-operation.
        </Heading>
      </VStack>
    );
  }

  return (
    <VStack
      maxWidth={'100%'}
      space={1}
      className="personal-info-container"
      testID={'onboarding-surgery'}
    >
      <VStack space={0} className="">
        <Heading fontSize={'3xl'} mb={2}>
          Have you ever had surgery due to your GI condition? (e.g. bowel
          resection)
        </Heading>
      </VStack>
      <Center maxWidth={'100%'}>
        <HStack>
          <Pressable
            w={{ base: '100px', md: '200px' }}
            m=".5rem"
            onPress={() => {
              updateGISurgery(true);
            }}
          >
            <Box
              bg={
                formData['hasHadGISurgery'] === true
                  ? 'secondary.500'
                  : 'light.50'
              }
              p="5"
              borderWidth="1"
              borderColor="muted.400"
              borderRadius="lg"
              style={{}}
            >
              <Text
                m="auto"
                color={
                  formData['hasHadGISurgery'] === true
                    ? 'light.50'
                    : 'secondary.500'
                }
                fontWeight="medium"
                fontSize="xl"
                testID={'onboarding-surgery-yes-answer'}
              >
                Yes
              </Text>
            </Box>
          </Pressable>

          <Pressable
            w={{ base: '100px', md: '200px' }}
            m=".5rem"
            onPress={() => {
              updateGISurgery(false);
            }}
          >
            <Box
              bg={
                formData['hasHadGISurgery'] === false
                  ? 'secondary.500'
                  : 'light.50'
              }
              p="5"
              borderWidth="1"
              borderColor="muted.400"
              borderRadius="lg"
              style={{}}
            >
              <Text
                m="auto"
                color={
                  formData['hasHadGISurgery'] === false
                    ? 'light.50'
                    : 'secondary.500'
                }
                fontWeight="medium"
                fontSize="xl"
                testID={'onboarding-surgery-no-answer'}
              >
                No
              </Text>
            </Box>
          </Pressable>
        </HStack>
      </Center>
    </VStack>
  );
};
