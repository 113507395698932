import { VStack, Pressable, Text, Box, Heading, Center } from 'native-base';
import { FORM_ACTIONS } from '../../utils/formActions.js';

function PregnancyInfo({ formData, dispatch, blockPregnancy }) {
  const updatePregnancy = (data) => {
    dispatch({
      type: FORM_ACTIONS.UPDATE_FORM_FIELD,
      fieldName: `pregnant`,
      payload: data,
    });
  };

  if (blockPregnancy) {
    return (
      <VStack maxWidth={'100%'} space={1} className="personal-info-container">
        <Heading textAlign={'center'} mt={'3'}>
          Unfortunately, Ayble is not currently able to support people who are
          currently pregnant, plan to become pregnant during the program, or are
          nursing. We hope to carry out future scientific studies to understand
          nutrition and gut health prior to and during pregnancy.
        </Heading>
      </VStack>
    );
  }

  const pregnancyOptions = [
    'Yes',
    `No, but I'm currently nursing`,
    'No, but plan on becoming pregnant in the next 3 months',
    'No',
  ];

  return (
    <VStack
      maxWidth={'100%'}
      space={1}
      className="personal-info-container"
      testID={'onboarding-pregnancy'}
    >
      <VStack space={1} className="header">
        <Heading fontSixe={'5xl'} mb={5}>
          Are you pregnant?
        </Heading>
      </VStack>
      <Center maxWidth={'100%'}>
        <VStack space={3}>
          {pregnancyOptions.map((item, index) => {
            return (
              <Pressable
                key={index}
                onPress={() => updatePregnancy(item)}
                testID={'onboarding-pregnancy-item'}
              >
                <Box
                  bg={
                    formData['pregnant'] === item ? 'secondary.500' : 'light.50'
                  }
                  p="5"
                  borderWidth="1"
                  borderColor="muted.400"
                  borderRadius="lg"
                  style={{ height: '100%' }}
                >
                  <Text
                    color={
                      formData['pregnant'] === item
                        ? 'light.50'
                        : 'secondary.500'
                    }
                    fontWeight="medium"
                    fontSize="xl"
                  >
                    {item}
                  </Text>
                </Box>
              </Pressable>
            );
          })}
        </VStack>
      </Center>
    </VStack>
  );
}

export default PregnancyInfo;
