import { VStack, Heading, Box } from 'native-base';
import { FORM_ACTIONS } from '../../../../utils/formActions.js';
import CustomSliderLabels from '../../../Molecules/CustomSliderLabels.js';
import CustomSlider from '../../../Molecules/CustomSlider.js';

function IBSViewTwo({ formData, dispatch }) {
  const assessmentTrack = 'onboarding-ibs-all';

  const setBmSatisfaction = (data) => {
    dispatch({
      type: FORM_ACTIONS.UPDATE_FORM_FIELD,
      fieldName: 'onboarding-ibs-all-4',
      assessment: assessmentTrack,
      payload: data,
    });
  };

  const setSymptomInterference = (data) => {
    dispatch({
      type: FORM_ACTIONS.UPDATE_FORM_FIELD,
      fieldName: 'onboarding-ibs-all-5',
      assessment: assessmentTrack,
      payload: data,
    });
  };

  const setStressLevel = (data) => {
    dispatch({
      type: FORM_ACTIONS.UPDATE_FORM_FIELD,
      fieldName: 'onboarding-stress',
      payload: data,
    });
  };

  const assessmentObject = formData[assessmentTrack];

  return (
    <VStack w={{ base: '100%' }} px={'1em'} testID={'onboarding-ibs-view-2'}>
      {/* View 2 */}
      <VStack>
        <Heading fontSize={'3xl'} mb={'2'}>
          Almost done! Just a few more questions to get a comprehensive
          understanding of your gut health.
        </Heading>
        <Heading fontFamily="body" fontWeight={'300'} size={'md'} mb={'2'}>
          Over the last 10 days…
        </Heading>
      </VStack>

      <h3>On average how satisfied have you been with your bowel habits?</h3>
      <Box
        px={{ base: '28px', md: '14px' }}
        mb={5}
        testID={'onboarding-ibs-view-2-slider-1'}
      >
        <CustomSlider
          value={assessmentObject['onboarding-ibs-all-4']}
          setSliderValue={setBmSatisfaction}
          minValue={0}
          maxValue={10}
        />
        <CustomSliderLabels
          labels={['Very Happy', 'Just Ok', 'Very Unhappy']}
        />
      </Box>

      <h3>How much have your symptoms interfered with your life in general?</h3>
      <Box
        px={{ base: '28px', md: '14px' }}
        testID={'onboarding-ibs-view-2-slider-2'}
      >
        <CustomSlider
          value={assessmentObject['onboarding-ibs-all-5']}
          setSliderValue={setSymptomInterference}
          minValue={0}
          maxValue={10}
        />
        <CustomSliderLabels labels={['Not at all', 'Somewhat', 'Completely']} />
      </Box>

      <h3>How would you rate your stress level in general?</h3>
      <Box
        px={{ base: '28px', md: '14px' }}
        testID={'onboarding-ibs-view-2-slider-3'}
      >
        <CustomSlider
          value={formData['onboarding-stress']}
          setSliderValue={setStressLevel}
          minValue={0}
          maxValue={10}
        />
        <CustomSliderLabels labels={['Very low', 'Moderate', 'Very high']} />
      </Box>
    </VStack>
  );
}

export default IBSViewTwo;
