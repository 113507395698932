import React from 'react';
import { Box, HStack, Text } from 'native-base';
import { useAffiliateBanner } from '../Affiliate/useAffiliateBanner';
import { AffiliateType } from '../../models/affiliate';

const bannerMessageAffiliateTypeMap: Record<AffiliateType, string> = {
  [AffiliateType.employer]:
    'Talk to a digestive health expert - at no cost to you!',
  [AffiliateType.healthPlan]:
    'Talk to a digestive health expert, covered by your insurance',
  [AffiliateType.healthSystem]:
    'Talk to a digestive health expert, covered by your insurance',
};

export const Banner = () => {
  const { affiliate: fetchedAffiliate } = useAffiliateBanner({});

  const defaultBannerText = fetchedAffiliate?.affiliate_type
    ? bannerMessageAffiliateTypeMap[fetchedAffiliate.affiliate_type]
    : bannerMessageAffiliateTypeMap[AffiliateType.healthPlan];

  const bannerText = fetchedAffiliate?.banner_text || defaultBannerText;

  return (
    <HStack bgColor={'orange.500'} p={6} justifyContent={'center'}>
      <Text fontSize={['lg', 'xl']} fontWeight={'medium'} color={'white'}>
        <Box
          flex={1}
          display={'flex'}
          alignItems={'center'}
          testID={'affiliate-banner'}
        >
          <span style={{ textAlign: 'center' }}>{bannerText}</span>
        </Box>
      </Text>
    </HStack>
  );
};
