import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import * as Sentry from '@sentry/react';

const { REACT_APP_ENV_LABEL = 'unknown', REACT_APP_LOGROCKET_APP_ID = null } =
  process.env;

export const useLogRocket = () => {
  const startLogRocketRecording = () => {
    if (!REACT_APP_LOGROCKET_APP_ID) return;

    // LogRocket initialization
    LogRocket.init(REACT_APP_LOGROCKET_APP_ID, {
      release: REACT_APP_ENV_LABEL,
    });

    setupLogRocketReact(LogRocket);

    // Presence of a session URL guaranteed by the usage of getSessionURL callback
    LogRocket.getSessionURL((sessionURL) => {
      Sentry.configureScope((scope) => {
        scope.setExtra('sessionURL', sessionURL);
      });
    });
  };

  return { startLogRocketRecording };
};
