import React from 'react';
import ReactGA from 'react-ga4';
import './App.css';
import { NativeBaseProvider } from 'native-base';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AuthProvider } from './hooks';
import { theme } from './theme/theme';
import { FormDataProvider } from './utils/formReducer';
import { Root } from './Root';
import { ModalsProvider } from './hooks/useModalsContext';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'react-virtualized/styles.css';

const {
  REACT_APP_GTM_ID = 'G-DPBENLDNMC',
  REACT_APP_GTM_CONTAINER_ID = 'GTM-P273C4V',
} = process.env;

const gtmParams = { id: REACT_APP_GTM_CONTAINER_ID };
ReactGA.initialize(REACT_APP_GTM_ID);

const queryClient = new QueryClient();

function App() {
  return (
    <GTMProvider state={gtmParams}>
      <NativeBaseProvider theme={theme}>
        <EmotionThemeProvider theme={theme}>
          <ModalsProvider>
            <AuthProvider>
              <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} />
                <FormDataProvider>
                  <Root />
                </FormDataProvider>
              </QueryClientProvider>
            </AuthProvider>
          </ModalsProvider>
        </EmotionThemeProvider>
      </NativeBaseProvider>
    </GTMProvider>
  );
}

export default App;
