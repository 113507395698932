import { useUsersList } from '../../../api/healthCoach/useUsersList';
import { useSearchParams } from 'react-router-dom';
import { USER_TYPES } from '../Toolbar';
import { userPhaseProgressWeightMap } from '../utils';
import { computeMissedDays } from '../HealthCoachDashboardListItem';
import { useCallback, useEffect, useMemo } from 'react';

const SEARCH_FILTER_PARAM_KEY = 'search';
const COACH_PARAM_KEY = 'coach';
const DIAGNOSIS_PARAM_KEY = 'diagnosis';
const PHASE_PARAM_KEY = 'phase';
const USER_PROGRESS_PARAM_KEY = 'progress';
const USERS_WITHOUT_HC_PARAM_KEY = 'withoutHealthCoach';
const USERS_WITH_HC_PARAM_KEY = 'withHealthCoach';
const INACTIVE_USERS_PARAM_KEY = 'inactive';
const USER_TYPES_PARAM_KEY = 'types';

export const useHealthCoachDashboard = () => {
  // params in the URL
  const [searchParams, setSearchParams] = useSearchParams();
  // fetch the users
  const { usersList: users, isLoading: isLoadingUsers } = useUsersList();
  // all the params coming from the URL are read and prepared to be used
  const searchFilter = searchParams.get(SEARCH_FILTER_PARAM_KEY) || '';
  const selectedCoach = searchParams.get(COACH_PARAM_KEY);
  const selectedDiagnosis = searchParams.get(DIAGNOSIS_PARAM_KEY);
  const selectedPhase = searchParams.get(PHASE_PARAM_KEY);
  const selectedUserProgress = searchParams.get(USER_PROGRESS_PARAM_KEY);
  const filterUsersWithCoach = searchParams.get(USERS_WITH_HC_PARAM_KEY);
  const filterUsersWithoutCoach = searchParams.get(USERS_WITHOUT_HC_PARAM_KEY);
  const filterInactiveUsers = searchParams.get(INACTIVE_USERS_PARAM_KEY);
  const selectedUserTypesString = searchParams.get(USER_TYPES_PARAM_KEY);
  const selectedUserTypes = selectedUserTypesString
    ? JSON.parse(decodeURIComponent(selectedUserTypesString))
    : null;

  useEffect(() => {
    const initialSelectedUserTypes = !!selectedUserTypesString
      ? selectedUserTypes
      : [USER_TYPES.freeUser, USER_TYPES.trueUser];
    const encoded = encodeURIComponent(
      JSON.stringify(initialSelectedUserTypes)
    );
    updateSearchParams(USER_TYPES_PARAM_KEY, encoded);
    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSearchParams = useCallback(
    (key, value) =>
      value ? searchParams.set(key, value) : searchParams.delete(key),
    [searchParams]
  );

  const { coaches, diagnoses } = useMemo(() => {
    if (!users) {
      return { currentCoaches: {}, currentDiagnoses: {} };
    }

    const { currentCoaches, currentDiagnoses } = users.reduce(
      (acc, { diagnosis, coach_first_name, coach_last_name }) => {
        const coachName =
          coach_first_name && coach_last_name
            ? `${coach_first_name} ${coach_last_name}`
            : null;

        if (coachName && !acc.currentCoaches[coachName]) {
          acc.currentCoaches[coachName] = coachName;
        }

        if (diagnosis && !acc.currentDiagnoses[diagnosis]) {
          acc.currentDiagnoses[diagnosis] = diagnosis;
        }
        return acc;
      },
      { currentCoaches: {}, currentDiagnoses: {} }
    );

    const coachOptions = Object.keys(currentCoaches).map((key) => ({
      label: key,
      value: key,
    }));

    const diagnosisOptions = Object.keys(currentDiagnoses).map((key) => ({
      label: key,
      value: key,
    }));

    return { coaches: coachOptions, diagnoses: diagnosisOptions };
  }, [users]);

  const onFilterChange = useCallback(
    (searchFilter) => {
      updateSearchParams(SEARCH_FILTER_PARAM_KEY, searchFilter);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams, updateSearchParams]
  );

  const onCoachChange = useCallback(
    (selectedCoachOpt) => {
      const coach = selectedCoachOpt?.value || '';
      updateSearchParams(COACH_PARAM_KEY, coach);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams, updateSearchParams]
  );

  const onDiagnosisChange = useCallback(
    (selectedDiagnosisOpt) => {
      const diagnosis = selectedDiagnosisOpt?.value || '';
      updateSearchParams(DIAGNOSIS_PARAM_KEY, diagnosis);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams, updateSearchParams]
  );

  const onUserProgressChange = useCallback(
    (selectedUserProgress) => {
      const userProgress = selectedUserProgress?.value || '';
      updateSearchParams(USER_PROGRESS_PARAM_KEY, userProgress);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams, updateSearchParams]
  );

  const onPhaseChange = useCallback(
    (selectedPhaseOpt) => {
      const phase = selectedPhaseOpt?.value || '';
      updateSearchParams(PHASE_PARAM_KEY, phase);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams, updateSearchParams]
  );

  const onUserTypesChange = useCallback(
    (userTypes) => {
      const selectedUserTypes = userTypes.map((uType) => uType.value);
      const encoded = encodeURIComponent(JSON.stringify(selectedUserTypes));
      updateSearchParams(USER_TYPES_PARAM_KEY, encoded);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams, updateSearchParams]
  );

  const onFilterWithHealthCoachChange = useCallback(
    (shouldFilterByIncludeCoach) => {
      updateSearchParams(USERS_WITH_HC_PARAM_KEY, shouldFilterByIncludeCoach);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams, updateSearchParams]
  );

  const onFilterWithNonHealthCoachChange = useCallback(
    (shouldFilterByExcludeCoach) => {
      updateSearchParams(
        USERS_WITHOUT_HC_PARAM_KEY,
        shouldFilterByExcludeCoach
      );
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams, updateSearchParams]
  );

  const onFilterWithInactiveUsersChange = useCallback(
    (filterUsers) => {
      updateSearchParams(INACTIVE_USERS_PARAM_KEY, filterUsers);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams, updateSearchParams]
  );

  const filteredUsers = useMemo(() => {
    if (!users) return [];

    const sortedByUserPhaseProgress = users.sort((a, b) => {
      const userPhaseProgressA =
        userPhaseProgressWeightMap[a.user_phase_progress] || 0;
      const userPhaseProgressB =
        userPhaseProgressWeightMap[b.user_phase_progress] || 0;

      return userPhaseProgressB - userPhaseProgressA;
    });

    return sortedByUserPhaseProgress.filter(
      ({
        email,
        id,
        coach_first_name,
        coach_last_name,
        current_phase,
        diagnosis,
        user_phase_progress,
        first_name,
        last_name,
        last_gut_check,
        last_mindgut_activity,
        on_day_in_phase,
        user_type,
      }) => {
        const coachName =
          coach_first_name && coach_last_name
            ? `${coach_first_name} ${coach_last_name}`
            : null;

        const daysSinceLastMindGutActivity = last_mindgut_activity
          ? computeMissedDays(last_mindgut_activity)
          : '';
        const daysSinceLastGutCheck = computeMissedDays(last_gut_check);

        const userName =
          first_name && last_name ? `${first_name} ${last_name}` : null;

        const filterByEmailOrName =
          email.toLowerCase().includes(searchFilter.toLowerCase()) ||
          id.toLowerCase().includes(searchFilter.toLowerCase()) ||
          userName?.toLowerCase().includes(searchFilter.toLowerCase());

        return (
          filterByEmailOrName &&
          (selectedCoach ? coachName?.indexOf(selectedCoach) === 0 : true) &&
          (selectedDiagnosis ? diagnosis === selectedDiagnosis : true) &&
          (selectedUserProgress
            ? userPhaseProgressWeightMap[user_phase_progress] ===
              +selectedUserProgress
            : true) &&
          (selectedPhase ? current_phase === selectedPhase : true) &&
          (selectedUserTypes?.length
            ? selectedUserTypes.includes(user_type)
            : true) &&
          (filterUsersWithCoach ? coach_first_name === null : true) &&
          (filterUsersWithoutCoach ? coach_first_name !== null : true) &&
          (filterInactiveUsers
            ? (typeof daysSinceLastGutCheck == 'number' &&
                daysSinceLastGutCheck <= 30) ||
              (typeof daysSinceLastMindGutActivity == 'number' &&
                daysSinceLastMindGutActivity <= 30) ||
              (!daysSinceLastGutCheck &&
                !daysSinceLastMindGutActivity &&
                on_day_in_phase < 30)
            : true)
        );
      }
    );
  }, [
    filterInactiveUsers,
    filterUsersWithCoach,
    filterUsersWithoutCoach,
    searchFilter,
    selectedCoach,
    selectedDiagnosis,
    selectedPhase,
    selectedUserProgress,
    selectedUserTypes,
    users,
  ]);

  return {
    coaches,
    diagnoses,
    filteredUsers,
    filterInactiveUsers,
    filterUsersWithCoach,
    filterUsersWithoutCoach,
    isLoadingUsers,
    onFilterWithHealthCoachChange,
    onFilterWithInactiveUsersChange,
    onFilterWithNonHealthCoachChange,
    onCoachChange,
    onDiagnosisChange,
    onFilterChange,
    onPhaseChange,
    onUserProgressChange,
    onUserTypesChange,
    searchFilter,
    selectedCoach,
    selectedDiagnosis,
    selectedPhase,
    selectedUserProgress,
    selectedUserTypes,
  };
};
