import Sex from './Sex';
import Gender from './Gender';
import { Box, VStack } from 'native-base';

export const SexAndGender = ({ formData, dispatch }) => {
  return (
    <VStack>
      <Box mb={8}>
        <Sex dispatch={dispatch} formData={formData}></Sex>
      </Box>
      <Gender dispatch={dispatch} formData={formData} />
    </VStack>
  );
};
