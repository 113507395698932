export const initialUserData = {
  email: '',
  firstName: '',
  lastName: '',
  state: '',
  enterpriseCode: '',
  other: '',
  pregnant: '',
  isFromIapFlow: false,
  reduceSymptoms: false,
  haveMoreEnergy: false,
  beAbleToEatOut: false,
  goOutWithFriends: false,
  reduceStress: false,
  improveActivity: false,
  understandDisease: false,
  otherGoal: false,
  otherGoalValue: '',
  noDiagnosedGiCondition: false,
  yesDiagnosedGiCondition: false,
  diagnosis_ids: [],
  bloatingFlatulence: false,
  stomachPain: false,
  constipation: false,
  diarrhea: false,
  generallyNotWell: false,
  hasEatingDisorder: undefined,
  hasHadGISurgery: undefined,
  hasUlcerativeColitis: false,
  hasCrohnsDisease: false,
  'onboarding-uc': {
    'onboarding-uc-1': '',
    'onboarding-uc-2': '',
    'onboarding-uc-3': '',
    'onboarding-uc-4': '',
    'onboarding-uc-5': '',
    'onboarding-uc-5-value': 5,
    score: 0,
  },
  'onboarding-uc-ibs': {
    'onboarding-uc-ibs-1': 5,
    'onboarding-uc-ibs-2': 5,
    'onboarding-uc-ibs-3': 5,
    'onboarding-uc-ibs-4': 5,
    'onboarding-uc-ibs-5': 5,
    score: 0,
  },
  'onboarding-crohns': {
    'onboarding-crohns-1': 5,
    'onboarding-crohns-2': 5,
    'onboarding-crohns-3': 5,
    score: 0,
  },
  'onboarding-crohns-ibs': {
    'onboarding-crohns-ibs-1': 5,
    'onboarding-crohns-ibs-2': 5,
    'onboarding-crohns-ibs-3': 5,
    'onboarding-crohns-ibs-4': 5,
    'onboarding-crohns-ibs-5': 5,
    score: 0,
  },
  'onboarding-ibs-all': {
    'onboarding-ibs-all-1': 5,
    'onboarding-ibs-all-2': 5,
    'onboarding-ibs-all-3': 5,
    'onboarding-ibs-all-4': 5,
    'onboarding-ibs-all-5': 5,
    score: 0,
  },
  'onboarding-stress': 5,
  ibsConstipation: false,
  ibsMixed: false,
  ibsDiarrhea: false,
  ibsUnspecified: false,
  colitisBloatingFlatulence: false,
  colitisStomachPain: false,
  colitisConstipation: false,
  colitisDiarrhea: false,
  colitisBloodInStool: false,
  colitisUrgencyBathroom: false,
  colitisFrequentBathroom: false,
  colitisGenerallyNotWell: false,
  medications: null,
  // info about affiliate that we need to carry on through the onboarding process
  affiliate: null,
  goals: [],
};
