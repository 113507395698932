import { useQuery } from '@tanstack/react-query';
import { useApi, useAuth } from '../../hooks';
import { User } from '../../models/user';

export const useUsersList = () => {
  const { api } = useApi();
  const { isAuthenticated, loading, userToken } = useAuth();

  const fetchUsersList = async () => {
    const requestObj = {
      route: '/private/coach/users',
      token: userToken,
    };

    return await api(requestObj);
  };

  const { data, isLoading, isError } = useQuery<{ users: User[] }, Error>(
    ['hc-users-list', userToken],
    () => fetchUsersList(),
    {
      enabled: isAuthenticated && !loading && !!userToken,
      refetchOnWindowFocus: false,
    }
  );

  return { usersList: data?.users, isLoading, isError };
};
