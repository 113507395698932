import Banner from '../Banner';
import { Box, useBreakpointValue } from 'native-base';
import React from 'react';
import { useAffiliateBanner } from './useAffiliateBanner';

type Props = {
  affiliate?: string;
  referrer?: string;
};

export const AffiliateBanner = ({
  affiliate = undefined,
  referrer = undefined,
}: Props) => {
  const { affiliate: fetchedAffiliate } = useAffiliateBanner({
    affiliate,
    referrer,
  });

  const flexDir = useBreakpointValue({
    base: 'column',
    xl: 'row',
  });

  return (
    <Banner>
      <Box
        flex={1}
        display={'flex'}
        flexDirection={flexDir}
        alignItems={'center'}
        testID={'affiliate-banner'}
      >
        {fetchedAffiliate && fetchedAffiliate?.banner_text ? (
          <span style={{ textAlign: 'center' }}>
            {fetchedAffiliate?.banner_text}
          </span>
        ) : (
          <span style={{ textAlign: 'center' }}>
            Start your 7-day free trial with Ayble!
          </span>
        )}
      </Box>
    </Banner>
  );
};

export default AffiliateBanner;
