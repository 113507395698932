import React, { useCallback } from 'react';
import {
  Route,
  Routes,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { UserDashboardPage } from './UserDashboardPage';
import {
  DashboardPageStateProvider,
  useDashboardPageState,
} from '../../hooks/useDashboardPageState';
import { PhaseDrawer } from './PhaseDrawer';
import { UserDashboardHeader } from './UserDashboardHeader';
import { OverviewPanel } from './OverviewPanel';
import { IFrame } from './IFrame';
import { Box, Text } from 'native-base';
import { Loader } from '../Loader';

const { REACT_APP_HEALTHIE_URL } = process.env;

const ErrorMessage = () => (
  <Text color={'red.500'} mt={6} textAlign={'center'}>
    Cannot access this section as we couldn't retrieve associated{' '}
    <b>Healthie ID</b>.
    <br />
    Please contact the support team.
  </Text>
);

const SubRoutes = () => {
  const {
    state: { loading: isFetchingDashboardData, user: patient },
  } = useDashboardPageState();
  const { patient: patientId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handlePhaseLink = useCallback(
    (phase: string) => {
      navigate(`/user-details/${patientId}/phase/${phase}?${searchParams}`);
    },
    [navigate, patientId, searchParams]
  );

  if (isFetchingDashboardData) {
    return <Loader loading={isFetchingDashboardData} />;
  }

  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <UserDashboardPage
            header={<UserDashboardHeader />}
            content={
              <Box
                bg={'muted.100'}
                borderTopRadius={40}
                w={{ base: '100%', lg: '80%' }}
              >
                <OverviewPanel
                  onPhaseDetailsClick={handlePhaseLink}
                  onPhaseLinkClick={handlePhaseLink}
                />
              </Box>
            }
          />
        }
      >
        <Route
          path={'/phase/:phase'}
          element={
            <PhaseDrawer
              onClose={() =>
                navigate(`/user-details/${patientId}?${searchParams}`)
              }
            />
          }
        ></Route>
      </Route>

      <Route
        path="/charting-notes"
        element={
          <UserDashboardPage
            header={<UserDashboardHeader />}
            content={
              <Box bg={'muted.100'} w={'100%'}>
                {patient?.healthie_id ? (
                  <IFrame
                    src={`${REACT_APP_HEALTHIE_URL}/users/${patient.healthie_id}/private_notes`}
                  />
                ) : (
                  <ErrorMessage />
                )}
              </Box>
            }
          />
        }
      />

      <Route
        path="/goals"
        element={
          <UserDashboardPage
            header={<UserDashboardHeader />}
            content={
              <Box bg={'muted.100'} w={'100%'}>
                {patient?.healthie_id ? (
                  <IFrame
                    src={`${REACT_APP_HEALTHIE_URL}/users/${patient.healthie_id}/goals`}
                  />
                ) : (
                  <ErrorMessage />
                )}
              </Box>
            }
          />
        }
      />
    </Routes>
  );
};

export const UserDetailsRoute = () => {
  const { patient } = useParams();

  return (
    <DashboardPageStateProvider patientId={patient}>
      <SubRoutes />
    </DashboardPageStateProvider>
  );
};
