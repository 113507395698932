import React from 'react';
import { VStack, Box, Image, Heading } from 'native-base';

function AssessmentWrapUp({ user }) {
  const blob = './../blob.png';
  const styles = {
    navIcon: {
      backgroundSize: 'contain',
      resizeMode: 'contain',
    },
  };

  const userDiganosis = () => {
    if (user.yesDiagnosedGiCondition) {
      if (user.hasUlcerativeColitis) {
        return 'Ulcerative Colitis';
      } else if (user.hasCrohnsDisease) {
        return "Crohn's disease";
      } else {
        return 'IBS';
      }
    } else {
      return 'gut health issues';
    }
  };

  return (
    <VStack space={1} className="other-info-container">
      <Heading fontSize={'3xl'} mb={'2'}>
        You're not alone.
      </Heading>
      <Box
        mt={20}
        maxWidth={'600'}
        w={{ base: '100%', md: '90%' }}
        ml={'auto'}
        mr={'auto'}
        mb={'9'}
        minH="300"
        rounded={10}
        bg={'light.50'}
        px="10"
        py={8}
        alignItems="center"
        style={{}}
        position={'relative'}
        top={3}
      >
        <Box position={'absolute'} top={'-70px'}>
          <Image
            size={'160px'}
            alt="blob"
            style={styles.navIcon}
            source={{
              uri: blob,
            }}
          />
          <Heading fontSize={'5xl'} top={'-100px'} position={'relative'}>
            68%
          </Heading>
        </Box>
        <Heading fontWeight="medium" fontSize="2xl" mt={20} mb={5}>
          *68% of people with {userDiganosis()} completely resolve their
          symptoms after just 3 months on the Ayble platform
        </Heading>
        <Heading fontFamily={'body'} fontWeight={400} fontSize={'lg'}>
          "Ayble has been a game changer for me. It has done what my doctor
          could not – or would not – do for me. I now know how to live my best
          life thanks to this program!" <br />- Cheryl, 35
        </Heading>
        <Heading
          marginTop={'24px'}
          fontFamily={'body'}
          fontWeight={400}
          fontSize={'9px'}
          textAlign={'left'}
        >
          *Note: Results not guaranteed, your experience on Ayble may be
          different. Source: Jactel, et. al 2021
        </Heading>
      </Box>
    </VStack>
  );
}

export default AssessmentWrapUp;
