import * as React from 'react';
import Select from 'react-select';
import { useTheme } from 'native-base';

const CustomSelect = ({
  height = 48,
  isClearable = false,
  isDisabled = false,
  isMulti = false,
  isSearchable = false,
  menuPlacement = 'bottom',
  onChange,
  placeholder = '',
  options = [],
  value = undefined,
  width = null,
  maxWidth = 340,
}) => {
  const {
    colors: { muted, primary, secondary },
  } = useTheme();

  return (
    <Select
      isClearable={isClearable}
      isDisabled={isDisabled}
      isMulti={isMulti}
      isSearchable={isSearchable}
      menuPlacement={menuPlacement}
      menuPortalTarget={document.body}
      menuShouldScrollIntoView={false}
      options={options}
      onChange={onChange}
      placeholder={placeholder}
      styles={{
        menuPortal: (base) => ({
          ...base,
          fontSize: '14px',
          maxWidth,
          zIndex: 9999,
        }),
        container: (base) => ({
          ...base,
          width,
        }),
        control: (base, state) => ({
          ...base,
          fontSize: '14px',
          height,
          maxWidth,
          backgroundColor: state.isDisabled
            ? muted['100']
            : base.backgroundColor,
        }),
        valueContainer: (base) => ({
          ...base,
          maxHeight: '100%',
          overflow: 'scroll',
        }),
      }}
      theme={(theme) => ({
        ...theme,
        borderRadius: 4,
        colors: {
          ...theme.colors,
          neutral20: secondary['500'],
          neutral30: secondary['100'],
          primary: primary['800'],
          primary25: primary['50'],
          primary50: primary['100'],
        },
      })}
      value={value}
    />
  );
};

export { CustomSelect as Select };
