import styled from '@emotion/styled';
import { Heading, Image, Text, VStack } from 'native-base';
import { HtmlRenderer } from '../OnboardingForm/HtmlRenderer';

const ImgContainer = styled('div')`
  & > div > div {
    background-position: left;
  }
`;

export const DropheadItem = ({
  html,
  imgUrl,
  title,
}: {
  html: string;
  imgUrl: string;
  title: string;
}) => {
  return (
    <VStack
      flex={1}
      px={{ base: 8, lg: 12 }}
      py={[4, 0]}
      pb={[12, 12, 0]}
      pt={0}
    >
      <ImgContainer>
        <Image
          resizeMode={'contain'}
          size={'sm'}
          source={{
            uri: imgUrl,
          }}
          alt={title}
        />
      </ImgContainer>

      <Heading
        color={'white'}
        fontWeight={'normal'}
        letterSpacing={'0px'}
        pt={6}
        size={'xl'}
        textAlign="left"
        minHeight={[0, 0, 128]}
      >
        {title}
      </Heading>

      <Text color={'white'} fontSize={'xl'} fontWeight={'normal'}>
        <HtmlRenderer html={html} />
      </Text>
    </VStack>
  );
};
