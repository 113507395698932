import React from 'react';
import { HStack, VStack, Box, Checkbox } from 'native-base';
import { Input } from '../Form/Input';
import { Select } from '../Form/Select';
import { toTitleCase, userPhaseProgressWeightMap } from './utils';
import { useHealthCoachDashboard } from './hooks/useHealthCoachDashboard';

export const PHASE_OPTIONS = [
  { label: 'Phase 0', value: 'PhaseZero' },
  { label: 'Phase 1 - Identification', value: 'PhaseOne' },
  { label: 'Phase 2 - Elimination', value: 'PhaseTwo' },
  { label: 'Phase 3 - Reintroduction', value: 'PhaseThree' },
  { label: 'Phase 4 - Application', value: 'PhaseFour' },
];

export const USER_PROGRESS_OPTIONS = Object.keys(
  userPhaseProgressWeightMap
).map((key) => ({
  label: toTitleCase(key),
  value: userPhaseProgressWeightMap[key],
}));

// values equal to Users.UserType backend Enum
export const USER_TYPES = {
  internalUser: 'Internal user',
  trueUser: 'True user',
  freeTrialDidNotConvert: 'Free trial did not convert',
  betaUser: 'Beta user',
  freeUser: 'Free user',
  paymentFailed: 'Payment Failed',
};

export const Toolbar = () => {
  const {
    onFilterWithHealthCoachChange,
    filterUsersWithCoach,
    onFilterWithNonHealthCoachChange,
    filterUsersWithoutCoach,
    onFilterWithInactiveUsersChange,
    filterInactiveUsers,
    coaches,
    diagnoses,
    searchFilter,
    onCoachChange,
    onDiagnosisChange,
    onFilterChange,
    onUserProgressChange,
    onUserTypesChange,
    onPhaseChange,
    selectedCoach,
    selectedDiagnosis,
    selectedUserProgress,
    selectedPhase,
    selectedUserTypes,
  } = useHealthCoachDashboard();

  const checkboxFilters = [
    {
      label: 'Exclude users with a health coach',
      onChange: onFilterWithHealthCoachChange,
      isChecked: filterUsersWithCoach,
    },
    {
      label: 'Exclude users without a health coach',
      onChange: onFilterWithNonHealthCoachChange,
      isChecked: filterUsersWithoutCoach,
    },
    {
      label: 'Exclude inactive users',
      onChange: onFilterWithInactiveUsersChange,
      isChecked: filterInactiveUsers,
    },
  ];

  const selectedCoachOpt = selectedCoach
    ? { label: selectedCoach, value: selectedCoach }
    : null;

  const selectedDiagnosisOpt = selectedDiagnosis
    ? { label: selectedDiagnosis, value: selectedDiagnosis }
    : null;

  const selectedUserProgressOpt = selectedUserProgress
    ? USER_PROGRESS_OPTIONS.find((opt) => opt.value === +selectedUserProgress)
    : null;

  const selectedPhaseOpt = selectedPhase
    ? PHASE_OPTIONS.find((opt) => opt.value === selectedPhase)
    : null;

  const selectedUserTypesOpt =
    selectedUserTypes?.map((uType) => ({
      label: uType,
      value: uType,
    })) || [];

  return (
    <VStack>
      <HStack flexDirection={'row'} my={1} alignItems={'center'}>
        <Box minW={'25%'} px={2} my={2}>
          <Select
            height={32}
            isClearable={true}
            isSearchable={false}
            isMulti={true}
            placeholder="Select user types"
            onChange={onUserTypesChange}
            options={Object.values(USER_TYPES).map((uType) => ({
              label: uType,
              value: uType,
            }))}
            value={selectedUserTypesOpt}
          />
        </Box>

        {checkboxFilters.map((filter, i) => (
          <Box key={i} minW={150} px={1} my={2} flexShrink={1}>
            <Checkbox
              my={2}
              isChecked={filter.isChecked}
              colorScheme="green"
              onChange={filter.onChange}
            >
              <span>{filter.label}</span>
            </Checkbox>
          </Box>
        ))}
      </HStack>

      <HStack
        flexDirection={'row'}
        flexWrap={'wrap'}
        my={1}
        alignItems={'center'}
      >
        <Box w={'20%'} flexGrow={1} flexShrink={1} minW={150} px={1} my={2}>
          <Input
            height={36}
            isClearable={true}
            onChange={onFilterChange}
            placeholder="Email, name or ID"
            type="text"
            value={searchFilter}
          />
        </Box>

        <Box w={'20%'} flexGrow={1} flexShrink={1} minW={150} px={1} my={2}>
          <Select
            height={32}
            isClearable={true}
            isSearchable={false}
            placeholder="Select a diagnosis"
            onChange={onDiagnosisChange}
            options={diagnoses}
            value={selectedDiagnosisOpt}
          />
        </Box>

        <Box w={'20%'} flexGrow={1} flexShrink={1} minW={150} px={1} my={2}>
          <Select
            height={32}
            isClearable={true}
            isSearchable={false}
            placeholder="Select user progress"
            onChange={onUserProgressChange}
            options={USER_PROGRESS_OPTIONS}
            value={selectedUserProgressOpt}
          />
        </Box>

        <Box w={'20%'} flexGrow={1} flexShrink={1} minW={150} px={1} my={2}>
          <Select
            height={32}
            isClearable={true}
            isSearchable={false}
            placeholder="Select a phase"
            onChange={onPhaseChange}
            options={PHASE_OPTIONS}
            value={selectedPhaseOpt}
          />
        </Box>

        <Box w={'20%'} flexGrow={1} flexShrink={1} minW={150} px={1} my={2}>
          <Select
            height={32}
            isClearable={true}
            isSearchable={false}
            placeholder="Select a coach"
            onChange={onCoachChange}
            options={coaches}
            value={selectedCoachOpt}
          />
        </Box>
      </HStack>
    </VStack>
  );
};
