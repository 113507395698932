import { Badge, Flex, Pressable, Text, useTheme } from 'native-base';
import { toTitleCase, userPhaseProgressWeightMap } from './utils';
import React, { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const PHASE_MAP = {
  PhaseZero: 0,
  PhaseOne: 1,
  PhaseTwo: 2,
  PhaseThree: 3,
  PhaseFour: 4,
};

export const computeMissedDays = (lastActivityDate) => {
  if (lastActivityDate == null) {
    return '';
  }
  const lastActivityTimestamp = new Date(lastActivityDate).getTime();
  const timeDifference = Date.now() - lastActivityTimestamp;
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
  return Math.floor(daysDifference);
};

const computeMissedDaysText = (
  date,
  missedDays,
  options = { showTextWhenDateNotAvailable: false }
) => {
  if (!date && options?.showTextWhenDateNotAvailable) return 'Not Available';
  if (!date && !options?.showTextWhenDateNotAvailable) return '';

  // if more than 24 hours have been passed, return the number of day(s) ago
  if (missedDays === 1) return 'Yesterday';
  if (missedDays > 1) return `${missedDays} days ago`;

  // within the last 24 hours we distinguish two cases:
  // 1. if the last activity happened after midnight, we are in the same day
  // 2. if it happened before midnight, it is yesterday
  const today = new Date().getDate();
  const dateDay = new Date(date).getDate();

  if (today === dateDay) return 'Today';
  return 'Yesterday';
};

export const HealthCoachDashboardListItem = ({ user }) => {
  const {
    plan_name,
    mind_gut_program,
    last_mindgut_activity,
    coach_first_name,
    coach_last_name,
    current_phase,
    diagnosis,
    diagnosis_secondary,
    email,
    first_name,
    id,
    last_name,
    last_gut_check,
    user_phase_progress,
    on_day_in_phase,
    user_type,
  } = user;

  const [searchParams] = useSearchParams();

  const {
    colors: { orange, red, secondary, tertiary },
  } = useTheme();
  const navigate = useNavigate();

  const computeBadgeStyle = useCallback(
    (userPhaseProgress) => {
      const weight = userPhaseProgressWeightMap[userPhaseProgress];

      const baseStyle = {
        _text: {
          fontWeight: 'bold',
        },
      };

      if (weight < 2) return baseStyle;

      if (weight < 3) return { ...baseStyle, bg: secondary['100'] };

      if (weight < 4) {
        return {
          _text: {
            color: orange['900'],
            fontWeight: 'bold',
          },
          bg: tertiary['500'],
        };
      }

      return { ...baseStyle, bg: red['600'] };
    },
    [orange, red, secondary, tertiary]
  );

  const daysSinceLastGutCheck = computeMissedDays(last_gut_check);
  const daysSinceLastMindGutActivity = computeMissedDays(last_mindgut_activity);

  return (
    <Pressable
      key={email}
      mb={6}
      onPress={() => navigate(`/user-details/${id}?${searchParams}`)}
    >
      <Flex
        direction="row"
        p={3}
        bg="muted.100"
        borderRadius="md"
        justifyContent="space-between"
        style={{ cursor: 'pointer' }}
      >
        <Flex direction="column" justifyContent="center" width={230}>
          <Flex alignItems="center" direction="row">
            <Text isTruncated fontWeight={600} fontSize={'lg'}>
              {first_name || '-'} {last_name || '-'}
            </Text>
          </Flex>
          <Text isTruncated fontSize={'xs'}>
            {email}
          </Text>
          <Text isTruncated fontSize={'xs'}>
            {id}
          </Text>
        </Flex>
        <Flex direction="column" justifyContent="center" p={2} width={120}>
          {diagnosis && <Text>{diagnosis}</Text>}
          {diagnosis_secondary && <Text>{diagnosis_secondary}</Text>}
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="center"
          direction="row"
          width={116}
        >
          {userPhaseProgressWeightMap[user_phase_progress] && (
            <Badge
              variant={
                userPhaseProgressWeightMap[user_phase_progress] > 1
                  ? 'solid'
                  : 'outline'
              }
              width={103}
              borderRadius={'sm'}
              {...computeBadgeStyle(user_phase_progress)}
            >
              {toTitleCase(user_phase_progress)}
            </Badge>
          )}
        </Flex>
        <Flex direction="column" justifyContent="center" p={2} width={100}>
          <Text fontWeight={600}>Last GC:</Text>
          <Text>
            {computeMissedDaysText(last_gut_check, daysSinceLastGutCheck, {
              showTextWhenDateNotAvailable: true,
            })}
          </Text>
        </Flex>
        <Flex direction="column" justifyContent="center" width={88}>
          <Text fontWeight={600}>Phase {PHASE_MAP[current_phase]}:</Text>
          <Text fontWeight={600}>
            {on_day_in_phase ? `Day ${on_day_in_phase}` : '--'}
          </Text>
        </Flex>
        <Flex alignItems="center" direction="row" width={110}>
          <Badge colorScheme="info">
            {`${coach_first_name || 'No'} ${coach_last_name || 'Coach'}`}
          </Badge>
        </Flex>
        <Flex
          alignItems="flex-start"
          direction="column"
          justifyContent={'center'}
          width={100}
        >
          <Text isTruncated>{user_type}</Text>
          <Text isTruncated fontWeight={'bold'}>
            {plan_name || 'Unknown'}
          </Text>
        </Flex>
        <Flex
          alignItems="flex-start"
          direction="column"
          justifyContent={'center'}
          width={100}
        >
          <Text>{mind_gut_program}</Text>
          <Text>
            {computeMissedDaysText(
              last_mindgut_activity,
              daysSinceLastMindGutActivity
            )}
          </Text>
        </Flex>
      </Flex>
    </Pressable>
  );
};
