import { HStack, Text } from 'native-base';

const Banner = ({ children }) => {
  return (
    <HStack p={3} justifyContent={'center'}>
      <Text fontSize={'lg'} fontWeight={'normal'}>
        {children}
      </Text>
    </HStack>
  );
};

export default Banner;
