import { Button, Flex, VStack, Text, useBreakpointValue } from 'native-base';
import { DropheadItem } from './DropheadItem';
import { useAffiliateBanner } from '../Affiliate/useAffiliateBanner';
import { AffiliateType } from '../../models/affiliate';

const boardUrl = '../../board-hearts.png';
const pcUrl = '../../personal-computer-hearts.png';
const creditCardUrl = '../../credit-card.png';
const employeeCardUrl = '../../employee-card.png';

type Item = {
  imgUrl: string;
  text: string;
  title: string;
};

const HEALTH_PLAN_ITEMS: Item[] = [
  {
    imgUrl: boardUrl,
    text: 'Share your gut health concerns and goals, and our digestive experts will create a personalized plan for lasting relief.',
    title: 'Get your custom care plan',
  },
  {
    imgUrl: pcUrl,
    text: 'Use the Ayble Health app to message your care team, identify your trigger foods, build psychology skills for a calmer gut, track symptoms, buy groceries and more!',
    title: 'Meet your care team and find relief',
  },
  {
    imgUrl: creditCardUrl,
    text: 'We accept most insurance plans, so you can focus on your health while we handle the rest. Low-cost plans are also available.',
    title: 'Pay with insurance',
  },
];

const EMPLOYER_ITEMS: Item[] = [
  {
    imgUrl: boardUrl,
    text: 'Share your gut health concerns and goals, and our digestive experts will create a personalized plan for lasting relief.',
    title: 'Get your custom care plan',
  },
  {
    imgUrl: pcUrl,
    text: 'Use the Ayble Health app to message your care team, identify your trigger foods, build psychology skills for a calmer gut, track symptoms, buy groceries and more!',
    title: 'Meet your care team and find relief',
  },
  {
    imgUrl: employeeCardUrl,
    text: 'Ayble Health is available at no direct cost to you. Verify your eligibility today! Finally, find digestive relief and feel better, for longer!',
    title: 'All at no cost to you',
  },
];

const HEALTH_SYSTEM_ITEMS: Item[] = [
  {
    imgUrl: boardUrl,
    text: 'Share your gut health concerns and goals, and our digestive experts will create a personalized plan for lasting relief alongside your provider.',
    title: 'Get your custom care plan',
  },
  {
    imgUrl: pcUrl,
    text: 'Use the Ayble Health app to message your care team, identify your trigger foods, build psychology skills for a calmer gut, track symptoms, buy groceries and more!',
    title: 'Meet your care team and find relief',
  },
  {
    imgUrl: creditCardUrl,
    text: 'We accept most insurance plans, so you can focus on your health while we handle the rest. Low-cost plans are also available.',
    title: 'Pay with insurance',
  },
];

const AffiliateTypeItemsMap: Record<AffiliateType, Item[]> = {
  [AffiliateType.employer]: EMPLOYER_ITEMS,
  [AffiliateType.healthPlan]: HEALTH_PLAN_ITEMS,
  [AffiliateType.healthSystem]: HEALTH_SYSTEM_ITEMS,
};

type Props = {
  onGetStartedButtonClick: () => void;
};

export const Drophead = ({ onGetStartedButtonClick }: Props) => {
  const { affiliate: fetchedAffiliate } = useAffiliateBanner({});

  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });

  const defaultItems = fetchedAffiliate?.affiliate_type
    ? AffiliateTypeItemsMap[fetchedAffiliate.affiliate_type]
    : AffiliateTypeItemsMap[AffiliateType.healthPlan];

  // if body_text is present, we update the last item to contain that copy
  const actualAffiliateItems = fetchedAffiliate?.body_text
    ? [
        ...defaultItems.slice(0, 2),
        {
          ...defaultItems[2],
          text: fetchedAffiliate?.body_text,
        },
      ]
    : defaultItems;

  return (
    <VStack alignItems={'center'} bgColor={'secondary.500'} py={12}>
      <Flex direction={isMobile ? 'column' : 'row'}>
        {actualAffiliateItems.map(({ imgUrl, text, title }) => (
          <DropheadItem
            html={text}
            imgUrl={imgUrl}
            key={imgUrl}
            title={title}
          />
        ))}
      </Flex>

      <Button
        _text={{ color: 'secondary.900', textTransform: 'none' }}
        height={'72px'}
        minW={'190px'}
        mt={isMobile ? 0 : 12}
        onPress={onGetStartedButtonClick}
        rounded={'72px'}
        w={'30%'}
      >
        <Text color={'secondary.500'} fontSize={'2xl'} fontWeight={'black'}>
          Get Started
        </Text>
      </Button>
    </VStack>
  );
};
