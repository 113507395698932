import styled from '@emotion/styled';
import {
  Button,
  Heading,
  HStack,
  Image,
  Text,
  useBreakpointValue,
  VStack,
} from 'native-base';
import { AffiliateType } from '../../models/affiliate';
import { useAffiliateBanner } from '../Affiliate/useAffiliateBanner';

const redArrowUpImg = '../../red_arrow_up.png';
const celebrateImg = '../../celebrate-phase1-completion.png';

const ImgContainer = styled(VStack)`
  & > div > div {
    background-position: left;
  }
`;

// map of key-value pairs where the key is an affiliate type,
// and the value is a function returning the header and the subheader message elements.
type AffiliateTypeInfoFnMap = Record<
  AffiliateType,
  (affiliateName: string | undefined) => {
    header: React.ReactNode;
    subheader: React.ReactNode;
  }
>;

const messagesAffiliateTypeMap: AffiliateTypeInfoFnMap = {
  [AffiliateType.employer]: (affiliateName: string | undefined) => ({
    header: <>Expert digestive care free for {affiliateName} employees</>,
    subheader: (
      <>
        Access personalized, holistic GI care, from diet to mental health, at $0
        cost to you.
      </>
    ),
  }),
  [AffiliateType.healthPlan]: () => ({
    header: <>Expert Digestive Care Covered by Your Insurance</>,
    subheader: (
      <>
        Access personalized, holistic GI care, from diet to mental health, for
        as low as $0.
      </>
    ),
  }),
  [AffiliateType.healthSystem]: () => ({
    header: <>Expert Digestive Care Covered by Your Insurance</>,
    subheader: (
      <>
        Access personalized, holistic GI care, from diet to mental health, for
        as low as $0.
      </>
    ),
  }),
};

type Props = {
  onGetStartedButtonClick: () => void;
};

export const Headline = ({ onGetStartedButtonClick }: Props) => {
  const { affiliate: fetchedAffiliate } = useAffiliateBanner({});

  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });

  const genFn = fetchedAffiliate?.affiliate_type
    ? messagesAffiliateTypeMap[fetchedAffiliate.affiliate_type]
    : messagesAffiliateTypeMap[AffiliateType.healthPlan];
  const defaultElements = genFn(fetchedAffiliate?.name);

  const headerMessage = fetchedAffiliate?.header || defaultElements.header;
  const subheaderMessage =
    fetchedAffiliate?.subheader || defaultElements.subheader;

  return (
    <VStack
      alignItems={'center'}
      bgColor={'secondary.500'}
      position={'relative'}
    >
      <HStack
        bgColor={'muted.100'}
        m={0}
        mt={8}
        mx={5}
        p={[6, 20]}
        rounded={['32px', '64px']}
      >
        <VStack flex={2}>
          <Heading
            color={'black'}
            fontWeight={'medium'}
            letterSpacing={'0px'}
            fontSize={['4xl', '6xl']}
            textAlign="left"
          >
            {headerMessage}
          </Heading>

          <Text
            color={'black'}
            fontSize={['2xl', '2xl', '2xl', '3xl']}
            fontWeight={'medium'}
            pt={[4, 8]}
          >
            {subheaderMessage}
          </Text>

          <Button
            _text={{ color: 'secondary.900', textTransform: 'none' }}
            height={'72px'}
            minW={'190px'}
            mt={12}
            onPress={onGetStartedButtonClick}
            rounded={'72px'}
            w={['50%', '50%', '30%']}
          >
            <Text
              color={'secondary.500'}
              fontSize={['xl', '2xl']}
              fontWeight={'black'}
            >
              Get Started
            </Text>
          </Button>

          <HStack alignItems={'flex-end'} flex={1} ml={-12} mt={8}>
            <Image
              resizeMode={'contain'}
              size={'lg'}
              source={{
                uri: redArrowUpImg,
              }}
              alt="Red Arrow Up"
            />

            <Text
              color={'black'}
              fontSize={['md', 'md', 'xl']}
              fontWeight={'normal'}
              flexWrap={'wrap'}
            >
              <u>90%</u> of Ayble Health users significantly improve their GI
              symptoms*
              <br />
              Results you can feel in as little as <u>7</u> days!
            </Text>
          </HStack>
        </VStack>

        <ImgContainer
          bottom={['8px', '72px']}
          flex={1}
          justifyContent={'end'}
          position={isMobile ? 'absolute' : undefined}
          right={0}
        >
          <Image
            alt="Celebrate Image"
            height={{ base: '180px', md: '300px', lg: '500px' }}
            mb={{ base: '2/3', md: 0, lg: -20 }}
            ml={{ base: 4, lg: undefined }}
            resizeMode={'contain'}
            source={{
              uri: celebrateImg,
            }}
            width={{ base: '150px', md: undefined, lg: undefined }}
          />
        </ImgContainer>
      </HStack>

      <Text
        color={'white'}
        flexWrap={'wrap'}
        fontSize={['sm', 'xl']}
        fontWeight={'normal'}
        p={8}
      >
        *Note: Results not guaranteed, your experience on Ayble may be
        different. Source: Jactel, et. al 2021.
      </Text>
    </VStack>
  );
};
