import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Image,
  VStack,
  Text,
} from 'native-base';
import React from 'react';
import { useAnalyticsHelper } from '../../hooks/useAnalyticsHelper';
import SignUpInfo from './SignUpInfo';
import MailchimpForm from '../../utils/MailChimpForm';
import { TRACKING_PURCHASE_LOCAL_STORAGE_KEY } from './constants';

const links = [
  {
    href: 'https://www.ayblehealth.com/eula',
    text: 'Terms & Conditions',
    end: ', ',
  },
  {
    href: 'https://www.ayblehealth.com/privacy-policy',
    text: ' Privacy Policy',
    end: ', and ',
  },
  {
    href: 'https://www.ayblehealth.com/privacy-policy',
    text: 'Notice of HIPAA Privacy Practices',
    end: '.',
  },
];

const styles = {
  navIcon: {
    backgroundSize: 'contain',
    resizeMode: 'contain',
  },
  logo: {
    backgroundSize: 'contain',
    resizeMode: 'contain',
    height: '30px',
    width: '232px',
  },
};

const ValueBox = () => {
  const thankYouBlob = './../thank-you-blob.png';
  const harvard_logo = './../harvard_logo.png';
  const brigham_logo = './../brigham_logo.png';
  const mass_gen_logo = './../mass_gen_logo.png';
  const northwestern_logo = './../northwestern_logo.png';
  const logos = [mass_gen_logo, harvard_logo, brigham_logo, northwestern_logo];

  return (
    <>
      <Box
        mt={'90px'}
        maxWidth={'100%'}
        w={'100%'}
        ml={'auto'}
        mr={'auto'}
        mb={'9'}
        minH="300"
        rounded={10}
        bg={'light.50'}
        px="5"
        py={8}
        alignItems="center"
        position={'relative'}
        top={3}
      >
        <Box position={'absolute'} top={'-70px'}>
          <Image
            size={'160px'}
            alt="blob"
            style={styles.navIcon}
            source={{
              uri: thankYouBlob,
            }}
          />
          <Heading fontSize={'5xl'} top={'-100px'} position={'relative'}>
            81%
          </Heading>
        </Box>

        <Heading fontWeight="medium" fontSize="2xl" mt={20} mb={5}>
          *81% Reported significantly improved IBS or IBD symptoms
        </Heading>

        <Flex
          maxW={'100%'}
          flexDir={['column', 'column', 'row']}
          mb={6}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          {logos.map((item, index) => {
            return (
              <Box flexBasis={['100%', '100%', '24%']} p={2} key={index}>
                <Image
                  key={index}
                  style={styles.logo}
                  alt={'logo image'}
                  source={{
                    uri: item,
                  }}
                />
              </Box>
            );
          })}
        </Flex>
        <Heading
          fontFamily={'body'}
          fontWeight={400}
          fontSize={'9px'}
          textAlign={'left'}
        >
          *Note: Results not guaranteed, your experience on Ayble may be
          different. Source: Jactel, et. al 2021.
        </Heading>
      </Box>
    </>
  );
};

// 18_last_step_page
const EndOfFlow = ({
  dispatch,
  formData,
  isFormValid,
  setParentPage,
  submitRef,
  submitted,
  handleTosChange,
  tosChecked,
  handleMailchimp,
  handleSubmitted,
}) => {
  const { updateTrackingLayer } = useAnalyticsHelper();

  const handleButton = () => {
    // set this flag to track the purchase, we will clear it once
    // we track it in the thank you page
    localStorage.setItem(TRACKING_PURCHASE_LOCAL_STORAGE_KEY, true);

    updateTrackingLayer(`Create account`);
    handleSubmitted();
    handleMailchimp();
  };

  return (
    <VStack
      alignItems={'center'}
      space={1}
      className="other-info-container"
      maxW={'100%'}
      testID={'end-of-flow'}
    >
      <Heading fontSize={'3xl'} mb={'2'}>
        Join the Ayble Experience!
      </Heading>

      <Heading
        textAlign={'left'}
        fontFamily="body"
        fontWeight={'300'}
        size={'md'}
      >
        You'll get a personalized nutrition and mind-gut program along with
        ongoing support from an expert digestive care team to help improve your
        symptoms.
      </Heading>

      <SignUpInfo formData={formData} dispatch={dispatch} />

      <MailchimpForm
        setParentPage={setParentPage}
        metaData={formData}
        submitRef={submitRef}
      />

      <form
        name="tosForm"
        style={{ zIndex: -10, alignSelf: 'center', padding: '.5em 0' }}
      >
        <FormControl isInvalid={!tosChecked && submitted}>
          <Flex flexDirection="row" style={{ alignItems: 'flex-start' }}>
            <input
              type="checkbox"
              name="TOS"
              id="TOS"
              checked={tosChecked}
              onChange={handleTosChange}
              data-testid="tos-checkbox"
            />

            <Box zIndex={-10}>
              <label
                htmlFor="TOS"
                style={{
                  display: 'inline-block',
                  maxWidth: '400px',
                  lineHeight: '25px',
                  margin: '0 .5em',
                }}
              >
                By continuing, I agree that I am at least 18 years old and agree
                to Ayble's{' '}
                {links.map((link, index) => (
                  <Box key={index} display={'inline'}>
                    <a href={link.href} target="_blank" rel="noreferrer">
                      {link.text}
                    </a>
                    <Text style={{ color: 'black', fontWeight: 400 }}>
                      {link.end}
                    </Text>
                  </Box>
                ))}
              </label>
            </Box>
          </Flex>

          <FormControl.ErrorMessage h={4} ml={1} mt={0}>
            Please accept to terms and conditions before proceeding
          </FormControl.ErrorMessage>
        </FormControl>
      </form>

      <Button
        isDisabled={!isFormValid}
        ml={2}
        mt={6}
        block
        maxW={'240px'}
        size={'md'}
        onPress={handleButton}
      >
        Create an Account
      </Button>

      <ValueBox />
    </VStack>
  );
};

export default EndOfFlow;
