import { Box, Heading, HStack, Image, Text, theme, VStack } from 'native-base';
import styled from '@emotion/styled';

const imgSrc = './../high-five.svg';
const styles = {
  groceries: {
    width: '60%',
    height: undefined,
    resizeMode: 'contain',
    aspectRatio: 1356 / 987,
  },
};

const CircleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 16px;
`;

const Circle = styled.div`
  width: 60%;
  border-radius: 50%;
  border: 2px solid ${theme.colors.muted['900']};

  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

export const AybleEcosystem = () => {
  return (
    <VStack space={1}>
      <Heading fontSize={'3xl'} mb={'2'}>
        Beyond typical digestive care
      </Heading>
      <Heading fontFamily="body" fontWeight={'300'} size={'md'} pb={3}>
        Ayble is an entire personalized ecosystem of support for your GI
        journey, beyond the clinic. We're by your side every step of the way.
      </Heading>

      <HStack justifyContent={['space-around']} pt={12}>
        <Heading fontSize={['md', 'xl', '2xl']}>Digestive Care Now</Heading>
        <Heading fontSize={['md', 'xl', '2xl']}>
          Digestive Care with Ayble
        </Heading>
      </HStack>

      <CircleWrapper>
        <Circle>
          <Box
            position={'absolute'}
            w={'100%'}
            h={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            top={0}
            right={0}
          >
            <Image
              alt="Ayble Food Finder"
              style={styles.groceries}
              mx={'auto'}
              source={{
                uri: imgSrc,
              }}
              zIndex={1}
            />
          </Box>
          <Box
            position={'absolute'}
            h={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-around'}
            top={0}
            left={5}
          >
            <Box
              backgroundColor={'light.100'}
              p={2}
              ml={['28px', '80px', '96px']}
            >
              <Text
                fontSize={['xs', 'md', 'lg', 'xl']}
                color={'black'}
                fontWeight={'medium'}
                backgroundColor={'muted.900'}
              >
                Prescriptions
              </Text>
            </Box>
            <Box
              backgroundColor={'light.100'}
              p={2}
              ml={['40px', '78px', '96px']}
            >
              <Text
                color={'muted.900'}
                fontSize={['xs', 'md', 'lg', 'xl']}
                fontWeight={'medium'}
              >
                Surgery
              </Text>
            </Box>
          </Box>

          <Box
            position={'absolute'}
            h={'100%'}
            display={'flex'}
            alignItems={'flex-end'}
            justifyContent={['space-around']}
            top={0}
            right={-72}
          >
            <Box
              backgroundColor={'light.100'}
              p={2}
              mr={['72px', '96px', '156px', '144px']}
            >
              <Text
                fontSize={['xs', 'md', 'lg', 'xl']}
                color={'black'}
                fontWeight={'medium'}
                backgroundColor={'muted.900'}
              >
                Personalized Nutrition Program
              </Text>
            </Box>
            <Box
              backgroundColor={'light.100'}
              p={2}
              mr={['72px', '128px', '132px', '128px']}
            >
              <Text
                color={'muted.900'}
                fontSize={['xs', 'md', 'lg', 'xl']}
                fontWeight={'medium'}
              >
                Mind-Gut Program
              </Text>
            </Box>
            <Box
              backgroundColor={'light.100'}
              p={2}
              mr={['64px', '72px', '96px', '96px']}
            >
              <Text
                color={'muted.900'}
                fontSize={['xs', 'md', 'lg', 'xl']}
                fontWeight={'medium'}
              >
                Dedicated Health Coaches
              </Text>
            </Box>
            <Box
              backgroundColor={'light.100'}
              p={2}
              mr={['112px', '180px', '204px', '180px']}
            >
              <Text
                color={'muted.900'}
                fontSize={['xs', 'md', 'lg', 'xl']}
                fontWeight={'medium'}
              >
                Food Finder Tools
              </Text>
            </Box>
          </Box>
        </Circle>
      </CircleWrapper>
    </VStack>
  );
};
