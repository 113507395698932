import styled from '@emotion/styled';
import { Heading, ITheme } from 'native-base';
import { Headline } from './Headline';
import { Drophead } from './Drophead';
import { Logos } from './Logos';
import { Info } from './Info';
import { Claim } from './Claim';
import { Footer } from './Footer';

const Link = styled('a')<{ theme?: ITheme }>`
  color: black;
  transition: color linear 200ms;

  &:hover {
    color: ${(p) => p.theme.colors.primary['600']};
  }
`;

type Props = {
  onGetStartedButtonClick: () => void;
};

export const Body = ({ onGetStartedButtonClick }: Props) => {
  return (
    <>
      <Headline onGetStartedButtonClick={onGetStartedButtonClick} />

      <Heading py={12} fontSize={{ base: 'xl', sm: '2xl', md: '4xl' }}>
        Curious if Ayble Health is right for you?{' '}
        <Link href="mailto:support@ayblehealth.com">Email us!</Link>
      </Heading>

      <Drophead onGetStartedButtonClick={onGetStartedButtonClick} />
      <Logos />
      <Info />
      <Claim onButtonClick={onGetStartedButtonClick} />
      <Footer />
    </>
  );
};
