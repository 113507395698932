import styled from '@emotion/styled';
import { Box, ITheme, Text, VStack } from 'native-base';
import { BulletImage } from '../ui/BulletImage';
import { useAffiliateBanner } from '../Affiliate/useAffiliateBanner';
import { Affiliate, AffiliateType } from '../../models/affiliate';

const bubbleImg = './../mind-gut-i-darker.png';
const notesImg = './../notes.png';
const joyImg = './../joy.png';
const foodsImg = './../foods.png';
const shakingHandsImg = './../hands_shaking.png';
const mobilePhoneImg = './../mobile_phone.png';

const TOMATO = '#f15b33';
const MEDIUM_TURQUOISE = '#56cec6';
const GOLD = '#f7d31b';

const Item = styled('li')<{ theme?: ITheme }>`
  align-items: flex-start;
  color: ${(p) => p.theme.colors.secondary['500']};
  display: flex;
  margin-top: 32px;
`;

const DEFAULT_BULLETS = [
  {
    bg: GOLD,
    bgSize: undefined,
    imgUrl: mobilePhoneImg,
    text: 'Expert digestive care team you can talk to anytime, from anywhere',
    title: 'Convenient Virtual Care',
  },
  {
    bg: TOMATO,
    bgSize: '75%',
    imgUrl: notesImg,
    text: 'Find your trigger foods and build a diet that works for you',
    title: 'Eat with Confidence',
  },
  {
    bg: MEDIUM_TURQUOISE,
    bgSize: undefined,
    imgUrl: bubbleImg,
    text: 'Powerful guided meditations and practices to break the stress- symptom cycle',
    title: 'Calm Your Mind to Calm Your Gut',
  },
  {
    bg: TOMATO,
    bgSize: '50%',
    imgUrl: joyImg,
    text: 'Comprehensive gut health tracker to see how your symptoms change over time',
    title: 'Manage Symptoms Effectively',
  },
  {
    bg: MEDIUM_TURQUOISE,
    bgSize: '75%',
    imgUrl: foodsImg,
    text: 'Grocery-finding tool and barcode scanner to remove the guesswork at the store',
    title: 'Find Foods That Work for You',
  },
  {
    bg: GOLD,
    bgSize: '60%',
    imgUrl: shakingHandsImg,
    text: 'Educational content written by GI experts and clinicians',
    title: 'Learn From Gut Health Experts',
  },
];

const generateBulletPoints = (fetchedAffiliate: Affiliate | undefined) => {
  if (fetchedAffiliate?.affiliate_type !== AffiliateType.healthSystem) {
    return DEFAULT_BULLETS;
  }

  const [expertBullet, ...rest] = DEFAULT_BULLETS;
  return [
    {
      ...expertBullet,
      text: `Expert digestive care team you can talk to anytime from anywhere, in collaboration with your ${fetchedAffiliate?.name} provider.`,
    },
    ...rest,
  ];
};

const ListItem = ({
  bgColor,
  bgSize,
  children,
  img,
}: {
  bgColor: string;
  bgSize?: string;
  children;
  img: string;
}) => {
  return (
    <Item data-testId="signup-list-item">
      <BulletImage
        width={40}
        height={40}
        bgColor={bgColor}
        bgSize={bgSize}
        imgUrl={img}
      />
      <Text
        alignSelf={'center'}
        color={'white'}
        fontSize={'xl'}
        fontWeight={'medium'}
        ml={3}
        textAlign={'left'}
      >
        {children}
      </Text>
    </Item>
  );
};

export const BulletList = () => {
  const { affiliate: fetchedAffiliate } = useAffiliateBanner({});
  const bulletPoints = generateBulletPoints(fetchedAffiliate);

  return (
    <ul style={{ listStyleType: 'none', paddingLeft: '12px' }}>
      {bulletPoints.map(({ bg, bgSize, imgUrl, text, title }) => (
        <ListItem bgColor={bg} bgSize={bgSize} img={imgUrl} key={title}>
          <VStack>
            <Box pb={1}>{title}</Box>
            <Text fontSize={'lg'} fontWeight={'thin'}>
              {text}
            </Text>
          </VStack>
        </ListItem>
      ))}
    </ul>
  );
};
