import React from 'react';
import { Outlet } from 'react-router-dom';
import { Divider, Heading, ITheme, Text, useTheme, VStack } from 'native-base';
import styled from '@emotion/styled';
import { Toolbar } from './Toolbar';
import HeaderNav from '../HeaderNav/HeaderNav';
import { Loader } from '../Loader';
import { HealthCoachDashboardList } from './HealthCoachDashboardList';
import { useHealthCoachDashboard } from './hooks/useHealthCoachDashboard';

const Sticky = styled.div<{ theme?: ITheme }>`
  background-color: ${(p) => p.theme.colors.muted['50']};
  position: sticky;
  top: 0;
  z-index: 100;
`;

export const HealthCoachDashboard = () => {
  const {
    colors: { muted },
  } = useTheme();

  const { isLoadingUsers, filteredUsers } = useHealthCoachDashboard();

  return (
    <div
      className="health-coach-dashboard"
      style={{
        height: '100vh',
        backgroundColor: muted['50'],
      }}
    >
      <HeaderNav page={1} hideBack />

      <div
        style={{
          height: 'calc(100% - 64px)',
          margin: '0 auto',
          paddingLeft: '12px',
          paddingRight: '12px',
        }}
      >
        {isLoadingUsers ? (
          <Loader loading={isLoadingUsers} />
        ) : (
          <VStack p={6} h={'100%'}>
            <Heading color="black" pb={2} textAlign="left" size="xl">
              My Patients ({filteredUsers.length})
            </Heading>
            <Text color="black" fontSize="lg" fontWeight="normal">
              These are your patients and their progress. Click on a patient to
              see their profile & send them a message.
            </Text>

            <Sticky>
              <Divider mt={2} />

              <Toolbar />

              <Divider my={4} />
            </Sticky>

            <HealthCoachDashboardList users={filteredUsers} />
          </VStack>
        )}
      </div>
      <Outlet />
    </div>
  );
};
