import {
  VStack,
  Pressable,
  Text,
  Box,
  Heading,
  HStack,
  Center,
} from 'native-base';
import { FORM_ACTIONS } from '../../utils/formActions.js';

function EatingDisorderInfo({ formData, dispatch, hasEatingDisorder }) {
  const updateEatingDisorder = (data) => {
    dispatch({
      type: FORM_ACTIONS.UPDATE_FORM_FIELD,
      fieldName: `hasEatingDisorder`,
      payload: data,
    });
  };

  if (hasEatingDisorder) {
    return (
      <VStack maxWidth={'100%'} space={1} className="personal-info-container">
        <Heading>
          Unfortunately, Ayble is not currently designed to support those who
          have an active or history of an eating disorder.{' '}
        </Heading>
      </VStack>
    );
  }

  return (
    <VStack
      maxWidth={'100%'}
      space={1}
      className="personal-info-container"
      testID={'onboarding-diagnosis'}
    >
      <VStack space={0} className="">
        <Heading fontSize={'3xl'} mb={2}>
          Do you have an active diagnosis or history of an eating disorder (e.g.
          bulimia, anorexia, or similar diagnosis)?{' '}
        </Heading>
      </VStack>

      <Center maxWidth={'100%'}>
        <HStack>
          <Pressable
            w={{ base: '100px', md: '200px' }}
            m=".5rem"
            onPress={() => {
              updateEatingDisorder(true);
            }}
          >
            <Box
              bg={
                formData['hasEatingDisorder'] === true
                  ? 'secondary.500'
                  : 'light.50'
              }
              p="5"
              borderWidth="1"
              borderColor="muted.400"
              borderRadius="lg"
              style={{}}
            >
              <Text
                m="auto"
                color={
                  formData['hasEatingDisorder'] === true
                    ? 'light.50'
                    : 'secondary.500'
                }
                fontWeight="medium"
                fontSize="xl"
                testID={'onboarding-diagnosis-yes-answer'}
              >
                Yes
              </Text>
            </Box>
          </Pressable>

          <Pressable
            w={{ base: '100px', md: '200px' }}
            m=".5rem"
            onPress={() => {
              updateEatingDisorder(false);
            }}
          >
            <Box
              bg={
                formData['hasEatingDisorder'] === false
                  ? 'secondary.500'
                  : 'light.50'
              }
              p="5"
              borderWidth="1"
              borderColor="muted.400"
              borderRadius="lg"
              style={{}}
            >
              <Text
                m="auto"
                color={
                  formData['hasEatingDisorder'] === false
                    ? 'light.50'
                    : 'secondary.500'
                }
                fontWeight="medium"
                fontSize="xl"
                testID={'onboarding-diagnosis-no-answer'}
              >
                No
              </Text>
            </Box>
          </Pressable>
        </HStack>
      </Center>
    </VStack>
  );
}

export default EatingDisorderInfo;
