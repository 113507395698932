import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useDebounce } from '../../hooks/useDebounce';
import { CloseIcon, Pressable } from 'native-base';

const Container = styled.div`
  align-items: center;
  background-color: ${(p) => (p.readOnly ? p.theme.colors.muted['100'] : null)};
  border: 1px solid
    ${(p) => (p.readOnly ? '#E6E6E6' : p.theme.colors.secondary['500'])};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  height: ${(p) => `${p.height}px`};
  max-width: 320px; // 340 - (10 + 10) padding
  padding: 0px 10px;

  &:hover {
    border-color: ${(p) =>
      !p.readOnly ? p.theme.colors.secondary['100'] : null};
  }

  &:focus-within {
    border-color: ${(p) =>
      p.readOnly ? null : p.theme.colors.secondary['100']};
    outline: ${(p) =>
      p.readOnly ? null : `1px solid ${p.theme.colors.primary['800']}`};
    outline-offset: ${(p) => (p.readOnly ? null : '-2px')};
  }
`;

const INPUT_DEBOUNCE_TIME = 350;

const TextInput = styled.input`
  color: ${(p) => (p.readOnly ? '#999999' : null)};
  flex: 1 0;
  min-width: 50px;
  min-height: 25px;
  background-color: transparent;
  padding-left: 5px;
  border: 0;
  &:focus {
    outline: none;
  }
`;

const Icon = styled.div`
  flex: 0 0;
`;

export const Input = ({
  debounceTime = INPUT_DEBOUNCE_TIME,
  height = 44,
  isReadOnly = false,
  isClearable = false,
  max = undefined,
  min = undefined,
  onChange,
  placeholder = '',
  type = 'text',
  value: initialValue = undefined,
}) => {
  const [value, setValue] = useState(initialValue);
  const debouncedValue = useDebounce(value, debounceTime);

  useEffect(() => {
    if (debouncedValue !== undefined && !!onChange) {
      onChange(debouncedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <Container height={height} readOnly={isReadOnly}>
      <TextInput
        max={max}
        min={min}
        onChange={(evt) => setValue(evt.target.value)}
        placeholder={placeholder}
        readOnly={isReadOnly}
        type={type}
        value={value}
      />
      {!isReadOnly && isClearable && (
        <Icon>
          <Pressable variant={'closer'} onPress={() => setValue('')}>
            <CloseIcon size="4" color="primary.800" />
          </Pressable>
        </Icon>
      )}
    </Container>
  );
};
