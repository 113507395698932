import {
  Flex,
  Heading,
  Image,
  ITheme,
  Text,
  useBreakpointValue,
  VStack,
} from 'native-base';
import { BulletList } from './BulletList';
import { useAffiliateBanner } from '../Affiliate/useAffiliateBanner';
import styled from '@emotion/styled';

const foodScreenUrl = '../../food_screen.png';

const Link = styled('a')<{ theme?: ITheme }>`
  color: white;
  text-decoration: underline;
  transition: color linear 200ms;

  &:hover {
    color: ${(p) => p.theme.colors.primary['600']};
    cursor: pointer;
  }
`;

const FaqLink = () => {
  const { affiliate: fetchedAffiliate } = useAffiliateBanner({});

  if (!fetchedAffiliate?.faq_link) return null;

  return (
    <Text color={'white'} fontSize={'lg'} fontWeight={'medium'} pl={3} pt={6}>
      Questions? Read Ayble Health's{' '}
      <Link href={fetchedAffiliate?.faq_link} target={'_blank'}>
        FAQ
      </Link>
    </Text>
  );
};

export const Info = () => {
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  });

  return (
    <VStack
      alignItems={'center'}
      bgColor={'secondary.500'}
      p={{ base: 8, md: 12 }}
      py={12}
    >
      <Heading color={'white'} fontSize={['4xl', '6xl']} textAlign={'center'}>
        Whole-Person Virtual Care
      </Heading>

      <Text
        color={'white'}
        fontSize={['2xl', '3xl']}
        fontWeight={'normal'}
        pt={3}
        textAlign={'center'}
      >
        Everything your gut needs— join the Ayble experience!
      </Text>

      <Flex
        direction={isMobile ? 'column' : 'row'}
        pt={12}
        alignItems={'center'}
      >
        <Image
          resizeMode={'contain'}
          flex={1}
          height={470}
          width={300}
          source={{
            uri: foodScreenUrl,
          }}
          alt={'Food screen'}
        />

        <VStack>
          <BulletList />

          <FaqLink />
        </VStack>
      </Flex>
    </VStack>
  );
};
