import { Text, VStack } from 'native-base';
import React from 'react';
import { HealthCoachDashboardListItem } from './HealthCoachDashboardListItem';
import { AutoSizer, List } from 'react-virtualized';

export const HealthCoachDashboardList = ({ users }) => {
  const rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    style, // Style object to be applied to row (to position it)
  }) => {
    return (
      <div key={key} style={style}>
        <HealthCoachDashboardListItem user={users[index]} key={key} />
      </div>
    );
  };

  return (
    <VStack flex={'1 1 auto'}>
      {!users.length && (
        <>
          <Text textAlign={'center'}>
            There isn't any user with this configuration of filters.
          </Text>
          <Text textAlign={'center'}>
            Please, try to change your filter configuration on top of the page.
          </Text>
        </>
      )}

      <AutoSizer style={{ display: 'contents' }}>
        {({ height, width }) => (
          <List
            width={width}
            height={height}
            rowCount={users.length}
            rowHeight={100}
            rowRenderer={rowRenderer}
          />
        )}
      </AutoSizer>
    </VStack>
  );
};
