import React from 'react';
import { useParams } from 'react-router-dom';
import { useMeetingNotes } from '../../api/healthCoach/useMeetingNotes';
import { Box, Heading, HStack, Text, VStack } from 'native-base';
import { Accordions } from '../Form/Accordions';
import { Loader } from '../Loader';
import { MeetingHeader } from './MeetingHeader';

export const MeetingNotes = () => {
  const { patient } = useParams();
  const { meetingNotes, isLoading, isError } = useMeetingNotes(patient ?? '');

  if (isLoading) {
    return (
      <Box backgroundColor="muted.100" p={0}>
        <Loader loading={true} />
      </Box>
    );
  }

  return (
    <VStack>
      <HStack alignItems={'center'} height={'32px'}>
        <Heading size={'md'} fontWeight={'normal'} textAlign="left" pr={4}>
          Meetings
        </Heading>
      </HStack>

      {isError && (
        <Text mb={6} fontSize={'sm'} color={'error.600'}>
          An error occurred while trying to load or save the meeting notes:
          please refresh the page and try again.
        </Text>
      )}

      {!meetingNotes.length && (
        <Text fontSize={'sm'} italic py={3}>
          No meetings for this user
        </Text>
      )}

      {!!meetingNotes.length && (
        <Accordions
          content={(item) => (
            <VStack pl={5} pr={7}>
              <Text fontSize={'md'} italic={!item.notes}>
                {item.notes ?? 'No appointment notes'}
              </Text>
            </VStack>
          )}
          header={(item) => <MeetingHeader item={item} />}
          items={meetingNotes}
        />
      )}
    </VStack>
  );
};
